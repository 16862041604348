import { Injectable } from '@angular/core';
import { AppSettings } from './app-settings';
import { User } from '@agilox/common';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	/**
	 * saves the user and appsettings in the localstorage
	 */
	saveUserSettingsInLocalStorage(user: User, webAppSettings: AppSettings): void {
		localStorage.setItem('profile', JSON.stringify(user));
		localStorage.setItem('appsettings', JSON.stringify(webAppSettings.toJson()));
	}

	/**
	 * loads the wanted property from the localStorage
	 * @param property property
	 */
	loadFromLocalStorage(property: string): any {
		return JSON.parse(localStorage.getItem(property));
	}

	/**
	 * clears the local storage
	 */
	clearLocalStorage(): void {
		localStorage.removeItem('profile');
		localStorage.removeItem('appsettings');
	}
}
