import {
	ChangeDetectorRef,
	ComponentRef,
	Directive,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
	inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsSpinnerComponent } from '../../../shared/components/spinner/analytics-spinner.component';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Mode } from 'apps/analytics/src/app/general/shared/services/appsettings/mode.enum';
import { MachineService } from 'apps/analytics/src/app/general/shared/services/machine/machine.service';
import { Subscription } from 'rxjs';
import { ErrorMessages } from '../../../enums/error-messages.enum';
import { Machine } from '../../../shared/services/machine/machine';
import { NotAvailableComponent } from '../not-available/not-available.component';
import { NotForApplianceComponent } from '../not-for-appliance/not-for-appliance.component';
import { LoadingHandler } from './loading-handler';
import { EmptyStateComponent } from '@agilox/ui';
import { MachineModel } from '@agilox/common';

/**
 * A directive to handle the loading of machines effienctly.
 * For usage of this directive the microsynthax is recommended: https://angular.io/guide/structural-directives
 * example of usage:
 * <div *agiloxAnalyticsLoad="let elem of loadingHandler; let machineNames = machineNames; disableOnComb: true; disableOnSum: true; dataAsArray: true">
 */
@Directive({
	selector: '[agiloxAnalyticsLoad]',
})
export class LoadDirective implements OnInit, OnDestroy {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('agiloxAnalyticsLoadOf') loadingHandler: LoadingHandler<unknown, unknown>;

	/** parsed data from the endpoint */
	data: Array<unknown>;
	/** original data from the endpoint */
	private originalData: Array<unknown> | any;
	private dataSubscription: Subscription;
	private subscriptionExpired: boolean | undefined;

	/** name of all machines, which data was fetched for */
	private machineNames: Array<[string, string]> | undefined;
	/** subscription for get machine details */
	private machineDetailsSubscription: Subscription | undefined;

	/**
	 * is needed for do a type-checking on all select machines
	 * if a machin is appliance, the widgets should be disabled
	 */
	private machines: Array<Machine> | undefined;

	@Input('agiloxAnalyticsLoadDisableOnComb') disableOnComb: boolean = false;

	@Input('agiloxAnalyticsLoadDisableOnSum') disableOnSum: boolean = false;

	/** the data gets passed as an array to the markup (same goes for the machinenames) */
	@Input('agiloxAnalyticsLoadDataAsArray') dataAsArray: boolean = false;

	private componentRef: ComponentRef<unknown>;
	private appsettingsSubscriptions: Array<Subscription>;

	get isDisabled(): boolean {
		return (
			(this.disableOnComb && this.appsettings.webAppSettings.dateSelector.mode === Mode.comb) ||
			(this.disableOnSum && this.appsettings.webAppSettings.dateSelector.mode === Mode.sum)
		);
	}

	constructor(
		private templateRef: TemplateRef<unknown>,
		private viewContainerRef: ViewContainerRef,
		private changeDetector: ChangeDetectorRef,
		private appsettings: AppsettingsService,
		private machineService: MachineService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.loadData();
		this.appsettingsSubscriptions = this.appsettings.registerOnWebappSettingChanged({
			next: () => this.loadData(),
		});
	}

	ngOnDestroy() {
		this.componentRef?.destroy();
		this.appsettingsSubscriptions?.forEach((sub) => sub?.unsubscribe());
		this.machineDetailsSubscription?.unsubscribe();
	}

	/**
	 * loads the data from the given loadingHandler
	 * before starting loading, the spinner gets rendered
	 */
	loadData() {
		this.machineNames = null;
		this.data = null;
		this.subscriptionExpired = false;
		this.originalData = null;
		// load spinner or not-avaiable
		this.generateView();

		if (this.isDisabled) {
			return;
		}

		this.machineDetailsSubscription?.unsubscribe();
		this.machineDetailsSubscription = this.machineService.getMachineDetailsBySerial(
			(machines: Machine[]) => {
				this.machineNames = machines.map((machine: Machine) => [machine.serial, machine.name]);
				this.machines = machines;
				this.loaded();
			},
			this.appsettings.webAppSettings.selectedMachines
		);

		this.dataSubscription?.unsubscribe();
		this.dataSubscription = this.loadingHandler.fetchFunction((data: Array<unknown> | string) => {
			this.subscriptionExpired = data === ErrorMessages.NO_MACHINE_SUBSCRIPTION;
			if (typeof data !== 'string') {
				// if no parse-function given, just save the data
				this.originalData = data;
			}
			this.loaded();
		});
	}

	loaded() {
		/** It is possible that a "auth failed" messages comes when the users trys to load data in the same moment
		 * that the getStatus is called.
		 */
		if (
			this.originalData &&
			!Array.isArray(this.originalData) &&
			this.originalData.hasOwnProperty('status') &&
			!this.originalData['status']
		) {
			return;
		} else {
			if (!this.isDisabled && this.originalData && this.machineNames && this.machines) {
				this.data =
					this.loadingHandler.parseFunction?.(this.originalData, this.machineNames) ??
					this.originalData;
				this.loadingHandler.onLoadedFunction?.(this.data);
				this.generateView();
			} else if (this.subscriptionExpired) {
				this.generateView();
			}
		}
	}

	/**
	 * If the component is disabled it renders `agilox-analytics-not-available`
	 * If the component is still loading it renders `agilox-analytics-loading-handler`
	 * Else the given template gets rendered with loaded data, if the widget got disabled on combined mode, a json object gets returned and not an array
	 * As an addition the machinenames get passed under machineNames as a tuple-array with the format [serial, name]
	 */
	generateView() {
		this.viewContainerRef.clear();
		const currentMachine = this.machines?.find(
			(machine: Machine) => machine.serial === this.appsettings.webAppSettings.machineToShow
		);
		const isAppliance =
			this.appsettings.webAppSettings.dateSelector.mode === Mode.each &&
			currentMachine?.machineModel === MachineModel.APPLIANCE.toUpperCase();

		// evaluating a possible isThereData-function
		let isThereData: null | undefined | boolean = null;
		let isThereDataException = false;
		try {
			isThereData = this.loadingHandler?.isThereDataFunction?.(this.data);
		} catch {
			isThereDataException = true;
		}

		// render not-for-appliance component
		if (this.appsettings.dateSelector.mode === Mode.each && isAppliance) {
			this.componentRef = this.viewContainerRef.createComponent(NotForApplianceComponent);
			// render not-available component
		} else if (this.isDisabled) {
			this.componentRef = this.viewContainerRef.createComponent(NotAvailableComponent);
			// render empty state component with subscription expired message
		} else if (this.subscriptionExpired) {
			this.componentRef = this.viewContainerRef.createComponent(EmptyStateComponent);
			(this.componentRef as ComponentRef<EmptyStateComponent>).instance.text =
				this.translateService.instant('error_messages.no_machine_subscription');
			(this.componentRef as ComponentRef<EmptyStateComponent>).instance.isAbsolute = true;
			// render spinner
		} else {
			this.componentRef = this.viewContainerRef.createComponent(AnalyticsSpinnerComponent);
			// calcualate the data for the spinner
			let dataForSpinner: Array<unknown> | boolean | null = this.data;
			// if there is a datafunction given and there is no data
			if (this.loadingHandler?.isThereDataFunction && isThereData === false) {
				dataForSpinner = [];
			}
			(this.componentRef as ComponentRef<AnalyticsSpinnerComponent>).instance.data =
				dataForSpinner as Array<unknown>;
			(this.componentRef as ComponentRef<AnalyticsSpinnerComponent>).instance.isLoading = true;
		}
		this.componentRef.changeDetectorRef.markForCheck();

		// only render the components if not disabled, at least one machine is selected, all is loaded and there is data
		if (
			!this.subscriptionExpired &&
			!this.isDisabled &&
			!isAppliance &&
			this.appsettings.webAppSettings.selectedMachines.length &&
			// if no function given, then evaluate the data, if given evaluate the function
			this.machineNames &&
			((!this.loadingHandler?.isThereDataFunction && this.data?.length) || isThereData)
		) {
			// in combined-mode we return one entry and this only the case if do not want the data as an array
			const data: unknown | Array<unknown> =
				!this.dataAsArray && this.disableOnComb ? this.data[0] : this.data;
			const machineNames =
				!this.dataAsArray && this.disableOnComb ? this.machineNames[0] : this.machineNames;
			this.viewContainerRef.createEmbeddedView(this.templateRef, {
				$implicit: data,
				machineNames: machineNames,
			});
		}

		this.changeDetector.detectChanges();

		if (isThereDataException) {
			throw Error('the isThereDataFunction probably threw an exception');
		}
	}
}
