import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * displays 2 input-fields for inputting hours and minutes
 */
@Component({
	selector: 'agilox-analytics-timeselect',
	templateUrl: './timeselect.component.html',
	styleUrls: ['./timeselect.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSelectComponent implements OnDestroy {
	timeFormGroup: FormGroup;

	timeFormGroupSubscriptions: Array<Subscription>;

	dataLoaded: boolean;

	/**
	 * transforms milliseconds to minutes and hours
	 */
	@Input() set data(val: number) {
		this.setTimeFormGroup(val);

		this.timeFormGroupSubscriptions.forEach((subscription) => subscription?.unsubscribe());

		this.timeFormGroupSubscriptions.push(
			this.timeFormGroup.get('minute').valueChanges.subscribe(() => {
				const timeoutId = setTimeout(() => {
					this.validChange.emit(this.timeFormGroup.get('minute').valid);
					this.emitTime();
					clearTimeout(timeoutId);
				}, 300);
			})
		);

		this.timeFormGroupSubscriptions.push(
			this.timeFormGroup.get('hour').valueChanges.subscribe(() => {
				const timeoutId = setTimeout(() => {
					this.validChange.emit(this.timeFormGroup.get('hour').valid);
					this.emitTime();
					clearTimeout(timeoutId);
				}, 300);
			})
		);
	}

	@Output() dataChange = new EventEmitter<number>();

	@Input() valid: boolean;

	@Output() validChange = new EventEmitter<boolean>();

	/** disable minutes in time form group */
	@Input() set disableMinutes(value: boolean) {
		if (value) {
			this.timeFormGroup.get('minute').disable();
		}
	}

	_hourMax: number;
	/** max hours */
	@Input() set hourMax(val: number) {
		this._hourMax = val;
		this.timeFormGroup
			.get('hour')
			.setValidators([
				Validators.required,
				Validators.maxLength(2),
				Validators.pattern('[0-9]+'),
				Validators.max(val),
				Validators.min(0),
			]);
		this.timeFormGroup.get('hour').updateValueAndValidity();
	}
	get hourMax(): number {
		return this._hourMax;
	}

	constructor(private formBuilder: FormBuilder) {
		this.timeFormGroup = this.formBuilder.group({
			hour: new FormControl('00', [
				Validators.required,
				Validators.maxLength(2),
				Validators.pattern('[0-9]+'),
				Validators.max(99),
				Validators.min(0),
			]),
			minute: new FormControl('00', [
				Validators.required,
				Validators.maxLength(2),
				Validators.pattern('[0-9]+'),
				Validators.max(59),
				Validators.min(0),
			]),
		});
		this.timeFormGroupSubscriptions = new Array<Subscription>();
	}

	ngOnDestroy(): void {
		this.timeFormGroupSubscriptions.forEach((subscr) => {
			subscr?.unsubscribe();
		});
	}

	/**
	 * emits the time
	 */
	emitTime() {
		if (this.timeFormGroup.invalid) {
			return;
		}
		this.dataChange.emit(this.getTime());
	}

	/**
	 * returns the transformed hours and minutes in ms
	 */
	getTime(): number {
		const hour = Number(this.timeFormGroup.get('hour').value);
		const minute = Number(this.timeFormGroup.get('minute').value);
		return (hour * 60 + minute) * 60 * 1000;
	}

	/**
	 * sets the hours and minutes in the form group
	 * @param time time in ms
	 */
	setTimeFormGroup(time: number) {
		if (!this.timeFormGroup.invalid) {
			time = time / 1000 / 60 / 60;
			this.timeFormGroup.get('hour').setValue(this.getTimeInCorrectFormat(Math.floor(time)));
			const minutes = (time - Math.floor(time)) * 60;
			if (minutes !== 0 || this.timeFormGroup.get('minute').value !== minutes) {
				this.timeFormGroup
					.get('minute')
					.setValue(this.getTimeInCorrectFormat(Math.round((time - Math.floor(time)) * 60)));
			}
		}
	}

	/**
	 * returns the time in the correct format
	 * @param value time
	 */
	getTimeInCorrectFormat(value: number): string {
		if (value.toString().length === 0) {
			return '00';
		} else if (value.toString().length < 2) {
			return '0' + value;
		}
		return value.toString();
	}
}
