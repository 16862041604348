import { inject, Pipe, PipeTransform } from '@angular/core';
import { DetailedState } from '../../services/machine/detailed-state';
import { SelectOption } from '@agilox/ui-common';
import { Role, Vehicle } from '@agilox/common';
import { AppsettingsService } from '../../services/appsettings/appsettings.service';

@Pipe({
	name: 'vehicleMultiSelectOptions',
})
export class VehicleMultiSelectOptionsPipe implements PipeTransform {
	private appSettingsService: AppsettingsService = inject(AppsettingsService);
	transform(
		unions: Array<[string, Array<DetailedState>]>,
		selectedUnion: string,
		selectedVehicles: DetailedState[] = []
	): any {
		if (!unions) {
			return [];
		}
		const options: SelectOption<DetailedState>[] = [];
		unions.forEach((union) => {
			union[1].forEach((vehicle: DetailedState) => {
				options.push({
					title:
						vehicle.name +
						' (' +
						vehicle.serial +
						')' +
						' | ' +
						vehicle.union +
						(this.appSettingsService.user.role !== Role.customer ? ' | ' + vehicle.company : ''),
					group: vehicle.union,
					value: vehicle,
				});
			});
		});
		this.disableOptions(selectedUnion, options);
		const groups = this.groupByUnion(options);
		return this.sortGroups(groups, selectedUnion, selectedVehicles);
	}

	private sortGroups(
		groups: any[],
		selectedUnion: string,
		selectedVehicles: DetailedState[]
	): any[] {
		// if the group is selected, move it to the top
		const selectedGroup = groups.find((group) => group.name === selectedUnion);

		if (selectedGroup) {
			// sort the items in the selected group, selected vehicles first
			selectedGroup.options.sort(
				(a: SelectOption<DetailedState>, b: SelectOption<DetailedState>) => {
					return selectedVehicles?.find((vehicle) => vehicle.serial === a.value.serial) ? -1 : 1;
				}
			);

			// move to the top
			groups.splice(groups.indexOf(selectedGroup), 1);
			groups.unshift(selectedGroup);
		}
		return groups;
	}

	private disableOptions(selectedUnion: string, options: SelectOption<DetailedState>[]): void {
		options.forEach((option) => {
			option.isDisabled = !(!selectedUnion || option.group === selectedUnion);
		});
	}

	private groupByUnion(vehicles: SelectOption<DetailedState>[]): any[] {
		const groupedVehicles = new Map<string, SelectOption<DetailedState>[]>();
		vehicles.forEach((vehicle) => {
			if (groupedVehicles.has(vehicle.value.union)) {
				groupedVehicles.get(vehicle.value.union)?.push(vehicle);
			} else {
				groupedVehicles.set(vehicle.value.union, [vehicle]);
			}
		});
		return Array.from(groupedVehicles.entries()).map(([name, options]) => ({
			name,
			options,
		}));
	}
}
