<div id="body" [class]="pageName" [class.no-selectors]="!showSelectors">
	<ui-nav
		class="hide-mobile"
		[items]="desktopMenuPoints"
		[apps]="appNavMenuPoints"
		[activeApp]="activeApp"
		[environment]="authenticate.environment"
		(callbackEvent)="navCallback($event)"
		*ngIf="!displayMode"
	>
	</ui-nav>

	<agilox-analytics-header
		*ngIf="showSelectors && !displayMode"
		[(headerMenuOpen)]="headerMenuOpen"
	></agilox-analytics-header>

	<div class="body" [class.no-selectors]="!showSelectors" [class.is-display]="displayMode">
		<router-outlet></router-outlet>
		@if (error$ | async; as error) {
			<ui-overlay
				(closeOverlay)="onClose()"
				[showCloseButton]="true"
				[header]="error.type + '.header' | lowercase | translate"
			>
				<ui-error-content [error]="error" (closed)="onClose()"></ui-error-content>
			</ui-overlay>
		}
	</div>

	<ui-mobile-nav
		class="hide-desktop"
		[items]="mobileMenuPoints"
		[apps]="appNavMenuPoints"
		[activeApp]="activeApp"
		[environment]="authenticate.environment"
		(callbackEvent)="navCallback($event)"
		[hidden]="displayMode"
	></ui-mobile-nav>
</div>

@if (helpOpen) {
	<ui-overlay [header]="'help_overlay.title' | translate" (closeOverlay)="onCloseHelp()">
		<ui-help-content (closed)="onCloseHelp()"> </ui-help-content>
	</ui-overlay>
}
