import { Injectable, Type } from '@angular/core';
import { WidgetConfig } from '../shared/services/appsettings/widget-config';
import { WidgetBatteryCellVoltageComponent } from './widget-battery-cell-voltage/widget-battery-cell-voltage.component';
import { WidgetBatteryCurrentComponent } from './widget-battery-current/widget-battery-current.component';
import { WidgetBatteryDifferenceComponent } from './widget-battery-difference/widget-battery-difference.component';
import { WidgetBatteryLevelComponent } from './widget-battery-level/widget-battery-level.component';
import { WidgetBatteryTemperatureComponent } from './widget-battery-temperature/widget-battery-temperature.component';
import { WidgetBatteryUsageComponent } from './widget-battery-usage/widget-battery-usage.component';
import { WidgetChargeComponent } from './widget-charge-count/widget-charge-count.component';
import { WidgetCurrentSpeedComponent } from './widget-current-speed/widget-current-speed.component';
import { WidgetDistanceAbsoluteComponent } from './widget-distance-absolute/widget-distance-absolute.component';
import { WidgetDistanceComponent } from './widget-distance/widget-distance.component';
import { WidgetFailuresProcessComponent } from './widget-failures-process/widget-failures-process.component';
import { WidgetFailuresQuitComponent } from './widget-failures-quit/widget-failures-quit.component';
import { WidgetFailuresTechnicalComponent } from './widget-failures-technical/widget-failures-technical.component';
import { WidgetFailuresTypeHistoryComponent } from './widget-failures-type-history/widget-failures-type-history.component';
import { WidgetFailuresTypesComponent } from './widget-failures-types/widget-failures-types.component';
import { WidgetLiftHeightAbsoluteComponent } from './widget-lift-height-absolute/widget-lift-height-absolute.component';
import { WidgetLiftHeightComponent } from './widget-lift-height/widget-lift-height.component';
import { WidgetParkCountComponent } from './widget-park-count/widget-park-count.component';
import { WidgetPerformanceSummaryComponent } from './widget-performance-summary/widget-performance-summary.component';
import { WidgetPickupCountComponent } from './widget-pickup-count/widget-pickup-count.component';
import { WidgetProcessesCanceledComponent } from './widget-processes-canceled/widget-processes-canceled.component';
import { WidgetProcessesDropsComponent } from './widget-processes-drops/widget-processes-drops.component';
import { WidgetProcessesPickupsComponent } from './widget-processes-pickups/widget-processes-pickups.component';
import { WidgetProcessesWorkflowsComponent } from './widget-processes-workflows/widget-processes-workflows.component';
import { WidgetProductivityAnalysisComponent } from './widget-productivity-analysis/widget-productivity-analysis.component';
import { WidgetProductivityDistancePieComponent } from './widget-productivity-distance-pie/widget-productivity-distance-pie.component';
import { WidgetProductivityHistoryComponent } from './widget-productivity-history/widget-productivity-history.component';
import { WidgetProductivitySummaryComponent } from './widget-productivity-summary/widget-productivity-summary.component';
import { WidgetRotationAbsoluteComponent } from './widget-rotation-absolute/widget-rotation-absolute.component';
import { WidgetRotationDrivingUnitsComponent } from './widget-rotation-driving-units/widget-rotation-driving-units.component';
import { WidgetWeightAbsoluteComponent } from './widget-weight-absolute/widget-weight-absolute.component';
import { WidgetWeightAnalysisComponent } from './widget-weight-analysis/widget-weight-analysis.component';
import { WidgetWeightComponent } from './widget-weight/widget-weight.component';

@Injectable({
	providedIn: 'root',
})
export class WidgetConfigResolverService {
	/**
	 * returns the class of the wanted widget, used for the widget injection
	 * @param widget Widgetconfig of the wanted widget
	 */
	getWidget(widget: WidgetConfig): Type<any> {
		switch (widget.name) {
			case 'weight_absolute':
				return WidgetWeightAbsoluteComponent;
			case 'widget_weight':
				return WidgetWeightComponent;
			case 'current_speed':
				return WidgetCurrentSpeedComponent;
			case 'battery_level':
				return WidgetBatteryLevelComponent;
			case 'battery_temperature':
				return WidgetBatteryTemperatureComponent;
			case 'battery_current':
				return WidgetBatteryCurrentComponent;
			case 'cell_voltage':
				return WidgetBatteryCellVoltageComponent;
			case 'battery_difference':
				return WidgetBatteryDifferenceComponent;
			case 'pickup_count':
				return WidgetPickupCountComponent;
			case 'park_count':
				return WidgetParkCountComponent;
			case 'performance_summary':
				return WidgetPerformanceSummaryComponent;
			case 'productivity_analysis':
				return WidgetProductivityAnalysisComponent;
			case 'productivity_history':
				return WidgetProductivityHistoryComponent;
			case 'productivity_summary':
				return WidgetProductivitySummaryComponent;
			case 'distance':
				return WidgetDistanceComponent;
			case 'distance_absolute':
				return WidgetDistanceAbsoluteComponent;
			case 'charge_count':
				return WidgetChargeComponent;
			case 'distance_pie':
				return WidgetProductivityDistancePieComponent;
			case 'failures_quit':
				return WidgetFailuresQuitComponent;
			case 'failures_process':
				return WidgetFailuresProcessComponent;
			case 'failures_technical':
				return WidgetFailuresTechnicalComponent;
			case 'failures_types':
				return WidgetFailuresTypesComponent;
			case 'processes_canceled':
				return WidgetProcessesCanceledComponent;
			case 'processes_workflows':
				return WidgetProcessesWorkflowsComponent;
			case 'processes_pickups':
				return WidgetProcessesPickupsComponent;
			case 'processes_drops':
				return WidgetProcessesDropsComponent;
			case 'rotation_absolute':
				return WidgetRotationAbsoluteComponent;
			case 'rotation_driving_units':
				return WidgetRotationDrivingUnitsComponent;
			case 'weight_analysis':
				return WidgetWeightAnalysisComponent;
			case 'failures_history':
				return WidgetFailuresTypeHistoryComponent;
			case 'battery_usage':
				return WidgetBatteryUsageComponent;
			case 'lift_height':
				return WidgetLiftHeightComponent;
			case 'lift_height_absolute':
				return WidgetLiftHeightAbsoluteComponent;
		}

		throw Error('no valid widget found for: ' + widget.name);
	}

	/**
	 * checks an array of widgetconfigs and removes every widget which
	 * isn't a valid widget
	 */
	checkWidgets(widgets: Array<WidgetConfig>) {
		return widgets.filter((widget) => {
			try {
				return this.getWidget(widget);
			} catch {
				return false;
			}
		});
	}
}
