<div class="mode-selector">
	<div class="mode-selector__container">
		<div
			class="mode-selector__box"
			*ngFor="let mode of modes"
			(click)="selectMode(mode)"
			[ngClass]="{ 'is-selected': selectedMode === mode }"
			[attr.data-cy]="'mode-selector-' + mode"
		>
			<ui-icon [icon]="'mode_' + mode" size="2xl"></ui-icon>
			<span class="mode-selector__name">
				{{ 'modes.' + mode | translate }}
			</span>
			<p class="mode-selector__description">
				{{ 'modes.description.' + mode | translate }}
			</p>
		</div>
	</div>
	<div class="mode-selector__buttons">
		<ui-button
			[text]="'general.cancel' | translate"
			(buttonClicked)="invokeCancel()"
			type="link"
		></ui-button>
		<ui-button
			[text]="'general.save' | translate"
			(buttonClicked)="invokeSave()"
			[rightAligned]="true"
			data-cy="mode-selector-save"
			directiveGoogleAnalytics
			eventAction="click: change-mode"
			[eventLabel]="selectedMode"
		></ui-button>
	</div>
</div>
