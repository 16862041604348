/**
 * represents a machine for the sub(header)
 * it saves the color and the current state of it
 */
export class HeaderMachine {
	constructor(
		public serial: string,
		public shortSerial: string,
		public name: string,
		public emails: Array<string>,
		public union: string,
		public unionId: string,
		public shortUnionId: string,
		public company: string,
		public remoteReachable: boolean,
		public selected: boolean,
		public city: string,
		public machineModel: string,
		public collective: Array<string>,
		public subscriptionExpired: boolean
	) {}

	/**
	 * generates the machine from a json and sets no color
	 * @param data
	 */
	static generateFromJson(data: any) {
		return new HeaderMachine(
			data.serial,
			data.serial.slice(0, 8),
			data.name,
			data.permissions ? data.permissions.map((permission) => permission.email) : [],
			data.union,
			data.union_id,
			data.union_id ? data.union_id.slice(0, 8) : '',
			data.company_name,
			!!data.remote_reachable,
			false,
			data.city,
			data.machine_model,
			data.collective,
			data.subscription_expired
		);
	}

	/**
	 * the result of this method is used for searching through the machines in the header
	 */
	toString(): string {
		const collectives = this.collective.length ? this.collective?.join(', ') : '';
		return (
			this.serial +
			',' +
			this.name +
			',' +
			this.emails +
			',' +
			this.union +
			',' +
			this.unionId +
			',' +
			this.company +
			',' +
			this.machineModel +
			', ' +
			this.emails.join(', ') +
			', ' +
			collectives.toLowerCase() +
			', ' +
			this.city
		);
	}
}
