<div class="calendar">
	<div class="calendar__calendarHeading">
		<!-- CHEVRON LEFT -->
		<ui-icon
			class="calendar__arrowContainer"
			icon="x_arrow_left"
			[action]="true"
			[disabled]="year === years[0] && month === 0"
			type="dark"
			(iconClicked)="modifyMonth(false)"
		></ui-icon>

		<div class="calendar__calendarHeadingText">
			<!-- MONTH -->
			<div class="calendar__monthText">
				{{ 'general.datepicker.months.' + month | translate }}
			</div>

			<!-- YEAR -->
			<ui-select
				[(ngModel)]="year"
				[options]="yearOptions"
				(selected)="yearSelected()"
				class="calendar__yearSelect"
			>
			</ui-select>
		</div>

		<!-- CHEVRON RIGHT -->
		<ui-icon
			class="calendar__arrowContainer"
			icon="x_arrow_right"
			[action]="true"
			[disabled]="year === years[years.length - 1] && month === currentDay.getUTCMonth()"
			type="dark"
			(iconClicked)="modifyMonth(true)"
		>
		</ui-icon>
	</div>

	<!-- WEEK DAYS -->
	<div class="calendar__calendarDay" *ngFor="let day of [0, 1, 2, 3, 4, 5, 6]">
		<div class="calendar__calendarDayCenter">
			{{ 'general.datepicker.weekdays.' + day | translate }}
		</div>
	</div>
	<div
		class="calendar__calendarDay"
		*ngFor="let day of daysToDisplay"
		(click)="!dayClickable(day) ? selectDay(day) : false"
		[ngClass]="calendarStyle(day)"
	>
		<span class="calendar__calendarDayText" [ngClass]="calendarTextStyles(day)">{{ day.day }}</span>
	</div>
</div>
