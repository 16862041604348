import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Floor } from '../machine/floor.type';
import { Buffer } from 'buffer';

/**
 * is responsible for every file interaction
 */
@Injectable({
	providedIn: 'root',
})
export class FileService {
	/**
	 * downloads a file
	 * @param filename filename
	 * @param content content of the file
	 */
	downloadFile(filename: string, content: string) {
		const blob = new Blob([content], {
			type: 'text/plain;charset=utf-8',
		});
		saveAs(blob, filename);
	}

	/**
	 * Cannot use saveAs as this causes a js error in the browser
	 * @param filename
	 * @param content
	 */
	downloadBase64ToPng(filename: string, content: string) {
		const link = document.createElement('a');
		link.href = content;
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	downloadLZMA(filename: string, content: Buffer) {
		const blob = new Blob([new Uint8Array(content)]);
		saveAs(blob, filename);
	}
}
