import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { Machine } from '../../shared/services/chart-data/machine';
import { ReducedMachine } from '../../shared/services/chart-data/reduced-machine';
import { ChartDataService } from '../../shared/services/chart-data/chart-data.service';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';

/**
 * displays the battery-level at a certain timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-battery-level',
	templateUrl: './widget-battery-level.component.html',
	styleUrls: ['./widget-battery-level.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBatteryLevelComponent extends BaseWidgetDirective {
	loadingHandler: LoadingHandler<Machine, ReducedMachine>;

	barChart: boolean = true;

	constructor(public chartService: ChartDataService) {
		super();
		this.loadingHandler = new LoadingHandler<Machine, any>(
			chartService,
			chartService.getData,
			this.transformData.bind(this),
			null,
			(data: [Timestamp[], EntryConfig[]]) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data[0].length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	private transformData(data: Machine[]): [Timestamp[], EntryConfig[]] {
		return this.chartService.transformBatteryData(
			data,
			['battLvl', 'battLvl_2'],
			['widget.battery_current.title', 'widget.battery_current.battCur_2']
		);
	}
}
