import { Clipboard } from '@angular/cdk/clipboard';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { MachineComponent } from 'apps/analytics/src/app/general/shared/components/machine/machine.component';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { HeaderMachine } from 'apps/analytics/src/app/general/shared/services/machine/header-machine';
import { HeaderUnion } from 'apps/analytics/src/app/general/shared/services/machine/header-union';
import { Union } from 'apps/analytics/src/app/general/shared/services/machine/union';
import { Subscription } from 'rxjs';
import { MachineSelectorService } from '../../../general/shared/services/machine/machine-selector.service';
import { SaveService } from '../save/save.service';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
	selector: 'agilox-analytics-machine-selector',
	templateUrl: './machine-selector.component.html',
	styleUrls: ['./machine-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineSelectorComponent extends SubscriptionHandler implements OnDestroy, OnChanges {
	@Input() inView: boolean = false;

	private maxSelectedMachines: number = 20;
	selectedMachines: Array<string> = new Array<string>();
	selectedUnions: Array<HeaderMachine> = new Array<HeaderMachine>();

	/** search text */
	_searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	/** filters out machines and unions according to their toString function */
	set searchText(value: string) {
		// order machines and unions only when search text changed
		if (this._searchText?.length !== value?.length) {
			this.orderMachines();
			this.orderUnions();
		}
		this._searchText = value;
		if (!this._searchText) {
			this.machinesToDisplay = this.machines;
			this.unionsToDisplay = this.unions;
		} else {
			// no ordering here, we assume all items are in order
			this.machinesToDisplay = this.machines.filter((machine) =>
				machine.toString()?.toLowerCase().includes(this._searchText.toLowerCase())
			);
			this.unionsToDisplay = this.unions.filter((union) =>
				union.toString()?.toLowerCase().includes(this._searchText.toLowerCase())
			);
		}
		this.changeDetector.detectChanges();
	}

	get tooManyMachines(): boolean {
		return this.selectedMachines.length > this.maxSelectedMachines;
	}

	/** holds all machines */
	machines: Array<HeaderMachine> = new Array<HeaderMachine>();

	/** holds filtered machines */
	machinesToDisplay: Array<HeaderMachine> | undefined;

	/** holds all unions */
	private unions: Array<HeaderUnion> = new Array<HeaderUnion>();

	/** holds filtered unions */
	unionsToDisplay: Array<HeaderUnion> | undefined;

	/** subscription for get all machines */
	private getAllMachinesSubscription: Subscription | undefined;

	/** subscription for get all unions */
	private getAllUnionsSubscription: Subscription | undefined;

	/** rendered unions */
	@ViewChildren('unions') renderedUnions: QueryList<MachineComponent> | undefined;

	@ViewChild('unionsContainer') unionsContainer: ElementRef<HTMLDivElement> | undefined;

	/** rendered machines */
	@ViewChildren('machines') renderedMachines: QueryList<MachineComponent> | undefined;

	@ViewChild('machinesContainer') machinesContainer: ElementRef<HTMLDivElement> | undefined;

	@ViewChild('searchInputField') searchInputField: any | undefined;

	/** subscription of router events */
	private routerSubscription: Subscription | undefined;

	/** track by function */
	trackByFunction = (idx: number, item: HeaderMachine) => item.serial;

	constructor(
		private appsettings: AppsettingsService,
		saveService: SaveService,
		private changeDetector: ChangeDetectorRef,
		private clipboard: Clipboard,
		private machineSelectorService: MachineSelectorService
	) {
		super(saveService);

		this.getAllMachines();
		this.getAllUnions();
	}

	override ngOnDestroy() {
		this.getAllMachinesSubscription?.unsubscribe();
		this.getAllUnionsSubscription?.unsubscribe();
		this.routerSubscription?.unsubscribe();
	}

	/**
	 * fetches, orders and filters the machines
	 */
	private getAllMachines() {
		this.getAllMachinesSubscription?.unsubscribe();
		this.getAllMachinesSubscription = this.machineSelectorService.getAllMachines(
			(machines: HeaderMachine[]) => {
				this.machines = machines;
				this.appsettings.checkMachines(machines);

				this.selectedMachines = this.machines
					.filter((machine) =>
						this.appsettings.webAppSettings.selectedMachines.includes(machine.serial)
					)
					.map((machine) => machine.serial);

				this.machines?.forEach(
					(machine) =>
						(machine.selected = this.appsettings.webAppSettings.selectedMachines.includes(
							machine.serial
						))
				);
				this.machinesToDisplay = this.machines;
				this.orderMachines();
				this.changeDetector.markForCheck();
			}
		);
	}

	/**
	 * fetches, orders and filters the unions
	 */
	private getAllUnions() {
		this.getAllUnionsSubscription?.unsubscribe();
		this.getAllUnionsSubscription = this.machineSelectorService.getAllUnions(
			(unions: Array<Union>) => {
				this.unions = unions?.map((union) => {
					const isEveryMachineSelected =
						union.serials.length &&
						union.serials.every((serials) =>
							this.appsettings.webAppSettings.selectedMachines.includes(serials)
						);
					return new HeaderUnion(
						union.unionId,
						union.unionId.slice(0, 8),
						union.name,
						[],
						'',
						'',
						'',
						union.companyName,
						isEveryMachineSelected,
						union.serials,
						union.city
					);
				});
				this.orderUnions();
				this.unionsToDisplay = this.unions;
				this.selectedUnions = this.unions?.filter((union) => union.selected);
				this.changeDetector.markForCheck();
			}
		);
	}

	/**
	 * on click selectes all machines
	 */
	toggleAllMachines(selectAll: boolean) {
		if (this.machinesToDisplay?.length <= 20 || !selectAll) {
			const machinesToDisplaySerial = this.machinesToDisplay?.map((machine) => machine.serial);
			if (selectAll) {
				this.selectedMachines = this.machines
					.map((machine) => machine.serial)
					// select all machines which are currently visible and the previously selected machines
					.filter(
						(serial) =>
							machinesToDisplaySerial.includes(serial) || this.selectedMachines.includes(serial)
					);
			} else {
				// deselect all machines which are currently visible due some filter
				this.selectedMachines = this.selectedMachines.filter(
					(serial) => !machinesToDisplaySerial.includes(serial)
				);
			}
			if (selectAll) {
				this.selectedMachines = this.machines
					.map((machine) => machine.serial)
					.filter(
						(serial) =>
							this.machinesToDisplay?.map((toDisplay) => toDisplay.serial).includes(serial) ||
							this.selectedMachines.includes(serial)
					);
			} else {
				this.selectedMachines = this.selectedMachines.filter(
					(serial) => !this.machinesToDisplay?.map((machine) => machine.serial).includes(serial)
				);
			}

			this.machinesToDisplay?.forEach((machine) => {
				if (machine.selected !== selectAll) {
					machine.selected = selectAll;
					this.renderedMachines
						?.find((rendered) => rendered.machine.serial === machine.serial)
						?.changeDetectorRef.markForCheck();
				}
			});

			this.setUnionsSelected();
		}
	}

	/**
	 * orders the machine at first according to the serial, in the next step
	 * the selected machines come at first
	 */
	private orderMachines() {
		this.machines.sort((a: HeaderMachine, b: HeaderMachine) =>
			a.serial > b.serial ? 1 : a.serial < b.serial ? -1 : 0
		);
		this.machines = [
			...this.machines.filter((machine) => machine.selected),
			...this.machines.filter((machine) => !machine.selected),
		];
		this.machinesToDisplay = this.machines;
	}

	/**
	 * sorts the unions according to the company, in the next step
	 * the selected unions come at first
	 */
	private orderUnions() {
		this.unions?.sort((a: HeaderUnion, b: HeaderUnion) =>
			a.company > b.company ? 1 : a.company < b.company ? -1 : 0
		);
		this.unions = [
			...this.unions?.filter((union) => union.selected),
			...this.unions?.filter((union) => !union.selected),
		];
		this.unionsToDisplay = this.unions;
	}

	toggleMachine($event: any, machine: HeaderMachine) {
		if (this.selectedMachines.includes(machine.serial)) {
			const idx = this.selectedMachines.findIndex((serial) => serial === machine.serial);
			this.selectedMachines.splice(idx, 1);
		} else {
			this.selectedMachines.push(machine.serial);
		}

		machine.selected = this.selectedMachines.includes(machine.serial);
		this.setUnionsSelected();
		this.orderUnions();
		this.searchText = this._searchText;
	}

	/**
	 * Handles the hidden features of the machine
	 * right click -> copy serial to clipboard
	 * @param event
	 * @param machine
	 */
	handleMachineHiddenFeature(event: any, machine: HeaderMachine) {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
		this.clipboard.copy(machine.serial);
	}

	toggleUnion(union: HeaderUnion) {
		this.machines
			.filter((machine) => union.serials.includes(machine.serial)) // get all machines which belong to the same union
			.forEach((machine) => {
				// remove or add machine
				if (!union.selected && !this.selectedMachines.includes(machine.serial)) {
					this.scrollToTop(true);
					this.selectedMachines.push(machine.serial);
				} else if (union.selected && this.selectedMachines.includes(machine.serial)) {
					const idx = this.selectedMachines.findIndex(
						(selectedMachine) => selectedMachine === machine.serial
					);
					this.selectedMachines.splice(idx, 1);
				}
				machine.selected = !union.selected;
				this.renderedMachines
					?.find((rendered) => rendered.machine.serial === machine.serial)
					?.changeDetectorRef.markForCheck();
			});

		union.selected = !union.selected;
		this.selectedUnions = this.unions?.filter((union) => union.selected);
		this.renderedUnions
			.find((rendered) => rendered.machine.serial === union.serial)
			?.changeDetectorRef.markForCheck();
		this.orderMachines();
		this.searchText = this.searchText;
	}

	/**
	 * gets invoked when right mouse key was clicked on union
	 * @param $event mouse event
	 * @param machine selected item
	 */
	copyUnionName(event: MouseEvent, item: HeaderUnion) {
		// hide default context menu
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
		// copy union name to clipboard
		this.clipboard.copy(item.name);
	}

	/**
	 * checks the status of every union and if the status changed its marked for check
	 */
	setUnionsSelected() {
		this.unions?.forEach((union) => {
			if (
				!union.selected &&
				union.serials.length &&
				union.serials.every((serial) => this.selectedMachines.includes(serial))
			) {
				union.selected = true;
				this.scrollToTop(false);
				this.renderedUnions
					.find((rendered) => rendered.machine.serial === union.serial)
					?.changeDetectorRef.markForCheck();
			} else if (
				union.selected &&
				union.serials.length &&
				union.serials.some((serial) => !this.selectedMachines.includes(serial))
			) {
				union.selected = false;
				this.renderedUnions
					.find((rendered) => rendered.machine.serial === union.serial)
					?.changeDetectorRef.markForCheck();
			}
		});
		this.selectedUnions = this.unions?.filter((union) => union.selected);
	}

	save() {
		if (
			this.appsettings.webAppSettings.selectedMachines.length !== this.selectedMachines.length ||
			this.appsettings.webAppSettings.selectedMachines.some(
				(elem) => !this.selectedMachines.includes(elem)
			) ||
			this.selectedMachines.length === 0
		) {
			this.scrollToTop();
			this.appsettings.multipleMachinesClicked(this.selectedMachines);
		}
	}

	cancel() {
		this.selectedMachines = [...this.appsettings.webAppSettings.selectedMachines];
		this.renderedMachines?.forEach((machine) => {
			if (this.appsettings.webAppSettings.selectedMachines.includes(machine.machine.serial)) {
				machine.machine.selected = true;
			} else {
				machine.machine.selected = false;
			}
			machine.changeDetectorRef.markForCheck();
		});
		this.setUnionsSelected();
		this.orderMachines();
		this.orderUnions();
		this.scrollToTop();
		this.searchText = '';
		this.changeDetector.detectChanges();
	}

	/**
	 * scrolls machine and/or unions container to top
	 * @param scrollMachine true -> machines; false -> unions; undefined -> both
	 */
	scrollToTop(scrollMachine?: boolean) {
		if (scrollMachine === undefined) {
			if (this.unionsContainer) {
				this.unionsContainer.nativeElement.scrollTop = 0;
			}
			if (this.machinesContainer) {
				this.machinesContainer.nativeElement.scrollTop = 0;
			}
		} else {
			if (scrollMachine) {
				if (this.machinesContainer) {
					this.machinesContainer.nativeElement.scrollTop = 0;
				}
			} else {
				if (this.unionsContainer) {
					this.unionsContainer.nativeElement.scrollTop = 0;
				}
			}
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['inView']?.currentValue) {
			this.searchInputField.input.nativeElement.focus();
		}
	}
}
