import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoadingHandler } from '../load-directive/loading-handler';
import { Col } from './col.type';
import { HeaderIconsDisabled } from './header-icons-disabled.type';

/**
 * this component can be used for easy creation of table-chart
 */
@Component({
	selector: 'agilox-analytics-table-chart',
	templateUrl: './table-chart.component.html',
	styleUrls: ['./table-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableChartComponent implements OnInit {
	/** if the widget should be disabled on sum-mode */
	@Input() disableOnSum: boolean = false;
	/** if the widget should be disabled on combined-mode */
	@Input() disableOnComb: boolean = false;
	@Input() config: Col[] | undefined;
	@Input() hideTableHead: boolean = false;

	/** if the widget has a row with others */
	@Input() othersFieldExisting: boolean = false;
	@Input() interactive: boolean = false;

	@Input() fetchFunction: <T>(observer: T) => Subscription;
	@Input() parseFunction: <T, K>(data: T) => K;

	/** loading handler */
	loadingHandler: LoadingHandler<any, any>;

	/** if all items are displayed */
	expanded: boolean = false;

	@Output() clickedItem: EventEmitter<any> = new EventEmitter<any>();
	@Output() headerDisabled: EventEmitter<HeaderIconsDisabled> =
		new EventEmitter<HeaderIconsDisabled>();

	private translate: TranslateService = inject(TranslateService);

	/** generates the loading */
	ngOnInit() {
		this.loadingHandler = new LoadingHandler<any, any>(
			null,
			this.fetchFunction,
			this.parseFunction,
			null,
			(data: Array<any>) => {
				const res = data === null || data === undefined ? null : data.length > 0;
				this.headerDisabled.emit([!!res, data === null]);
				return res;
			}
		);
	}

	/**
	 * clicked on row in the table
	 * @param item clicked item
	 * @param data displayed data
	 */
	clickedOnItem(item: any, data: Array<any>) {
		// proof if clicked item is a translation key and there is an others field
		if (
			this.translate.instant(item[this.config[0][1]]) !== item[this.config[0][1]] &&
			this.othersFieldExisting
		) {
			// emit all elements that aren't 'others' and sets a additional param others to true
			this.clickedItem.emit({
				[this.config[0][1]]: data
					.map((element) => element[this.config[0][1]])
					.filter((element) => element !== item[this.config[0][1]]),
				others: true,
			});
		} else {
			this.clickedItem.emit(item);
		}
	}

	showAll() {
		this.expanded = !this.expanded;
	}
}
