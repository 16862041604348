import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import { saveDateFilter, setDateFilter } from './date-filter.actions';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';

@Injectable()
export class DateFilterEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveDateFilter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveDateFilter),
				mergeMap((action) => {
					return this.appSettingsService.saveGlobalDateFilter(action.value).pipe(
						map((filter: ApplicationSettings) => {
							return setDateFilter({ value: filter.dateSelector });
						})
					);
				})
			),
		{ dispatch: true }
	);
}
