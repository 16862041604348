/**
 * represents an ActionGroup
 */
export class ActionGroup {
	/**
	 * constructs an ActionGroup
	 * @param workflow workflow
	 * @param workflowName workflowName
	 * @param isGroup isGroup
	 */
	constructor(
		public workflow: string,
		public workflowName: string,
		public isGroup: boolean
	) {}

	/**
	 * generates ActionGroup from json
	 * @param data entry of the ActionGroup
	 */
	static generateFromJson(data: any): ActionGroup {
		return new ActionGroup(data.workflow, data.workflow_name, data.is_grouped_by);
	}
}
