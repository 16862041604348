import { environment } from '@analytics/env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Buffer } from 'buffer';
import { PartialObserver, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Station } from '../failure-order-workflow/station';
import { Floor } from './floor.type';
import { Machine } from './machine';
import { MachineSelect } from './machine-select';
import { MachineResponse } from '../../../../menupoints/failures/models/machine-response.interface';
import { CountriesService } from '@agilox/common';

/**
 * fetches, updates and creates machines
 */
@Injectable({
	providedIn: 'root',
})
export class MachineService {
	/**
	 * constructs the service
	 * @param appsettings needed for the appsettings
	 * @param http need for fetching data
	 * @param countryService
	 */
	constructor(
		private appsettings: AppsettingsService,
		private http: HttpClient,
		private countryService: CountriesService
	) {}

	/**
	 * fetches the names of all machines and returns the wanted one
	 * @param observer observer
	 * @param serial serial of the wanted name
	 */
	getNameOfMachine(observer: PartialObserver<string>, serial: string): Subscription {
		const reqObj = {
			serial: [serial],
			from: this.appsettings.webAppSettings.dateSelector.startDate / 1000,
			to: this.appsettings.webAppSettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post(environment.server + '/v2/MachineMgmt/machines', reqObj)
			.pipe(
				map((data: any) => {
					if (data.status) {
						return (data.machines as Array<any>).find((machine) => machine.serial === serial)?.name;
					} else {
						return null;
					}
				})
			)
			.subscribe(observer);
	}

	/**
	 * returns the name of the wanted machines
	 * @param observer observer
	 * @param serials array of the serials
	 */
	getNameOfMachines(
		observer: PartialObserver<Array<string>>,
		serials: Array<string>
	): Subscription {
		const reqObj = {
			serial: serials,
			from: this.appsettings.webAppSettings.dateSelector.startDate / 1000,
			to: this.appsettings.webAppSettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post(environment.server + '/v2/MachineMgmt/machines', reqObj)
			.pipe(
				map((data: any) => {
					let machines = new Array<string>();
					if (data.status) {
						machines = (data.machines as Array<any>).map((machine) => machine.name as string);
					}
					return machines;
				})
			)
			.subscribe(observer);
	}

	/**
	 * gets the machine select array by the machine serials
	 * @param observer observer
	 * @param serials machine serials
	 */

	getMachineSelectBySerial(
		observer: (data: Array<MachineSelect>) => void,
		serials: Array<string>
	): Subscription {
		const reqObj = {
			serial: serials,
			from: this.appsettings.webAppSettings.dateSelector.startDate / 1000,
			to: this.appsettings.webAppSettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post(environment.server + '/v2/MachineMgmt/machines', reqObj)
			.pipe(
				map((data: any) => {
					let machines: Array<MachineSelect> = new Array<MachineSelect>();
					if (data.status) {
						machines = (data.machines as Array<any>).map(
							(machine) => new MachineSelect(machine.name, machine.serial)
						);
					}
					return machines;
				})
			)
			.subscribe(observer);
	}

	/**
	 * get machine details by serial
	 * @param observer observer
	 * @param serials serials
	 */
	getMachineDetailsBySerial(
		observer: (data: Array<Machine>) => void,
		serials: Array<string>
	): Subscription {
		const reqObj = {
			serial: serials,
			from: this.appsettings.webAppSettings.dateSelector.startDate / 1000,
			to: this.appsettings.webAppSettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post(environment.server + '/v2/MachineMgmt/machines', reqObj)
			.pipe(
				map((data: any) => {
					let machines = new Array<Machine>();
					if (data.status) {
						machines = (data.machines as Array<any>).map((machine) =>
							Machine.generateFromJson(machine, this.countryService)
						);
					}
					return machines;
				})
			)
			.subscribe(observer);
	}

	getMachines(serials: string[]) {
		const body = {
			serial: serials,
			from: this.appsettings.webAppSettings.dateSelector.startDate / 1000,
			to: this.appsettings.webAppSettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post<MachineResponse>(environment.server + '/v2/MachineMgmt/machines', body)
			.pipe(
				map((response) => {
					return response.machines.map((machine) =>
						Machine.generateFromJson(machine, this.countryService)
					);
				})
			);
	}

	/**
	 * returns the svg maps
	 * @param observer gets notified
	 * @param serial serial of the machine
	 */
	getMaps(observer: (data: Array<Floor>) => void, serial: string): Subscription {
		const reqObject = {
			serial: [serial],
			from: this.appsettings.dateSelector.startDate / 1000,
			to: this.appsettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post(environment.server + '/v2/Stats/getMaps', reqObject)
			.pipe(
				map((data: any) => {
					if (data.status) {
						const res = new Array<Floor>();
						Object.keys(data.maps).forEach((key) => {
							res.push([
								+key,
								{
									name: data.maps[key][0].name,
									svg: Buffer.from(data.maps[key][0].svg, 'base64').toString('utf8'),
									start: data.maps[key][0].start,
									end: data.maps[key][0].end,
								},
							]);
						});
						return res;
					} else {
						return data;
					}
				})
			)
			.subscribe(observer);
	}

	/**
	 * returns the stations
	 * @param observer gets notified
	 * @param serial serial of the machine
	 */
	getStations(observer: (data: Array<Station>) => void, serial: string): Subscription {
		const reqObject = {
			serial: [serial],
			from: this.appsettings.dateSelector.startDate / 1000,
			to: this.appsettings.dateSelector.endDate / 1000,
		};
		return this.http
			.post(environment.server + '/v2/Stats/getStations', reqObject)
			.pipe(
				map((data: any) => {
					if (data.status) {
						const res = new Array<Station>();
						data.stations[serial]?.map((station) => {
							res.push(Station.generateFromJson(station));
						});

						return res;
					} else {
						return [];
					}
				})
			)
			.subscribe(observer);
	}
}
