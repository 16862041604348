<div>
	<span class="text-secondary-600 text-xs mb-1 block">{{ 'general.vehicles' | translate }}</span>

	<div uiDropdown [fullWidth]="true" (openChange)="onDropdownStateChange($event)">
		<div uiDropdownTrigger class="flex gap-0.5 w-full">
			<div
				class="flex rounded bg-white cursor-pointer h-10 w-full border border-secondary-300 py-px px-3 items-center justify-between"
			>
				@if (formControl.value?.length) {
					<div class="flex justify-between w-full items-center">
						<span class="line-clamp-1 break-all">
							{{ formControl.value[0].name }} ({{ formControl.value[0].serial }}) |
							{{ formControl.value[0].union }}
						</span>

						@if (formControl.value.length > 1) {
							<ui-pill
								[value]="'+' + (formControl.value.length - 1).toString()"
								class="mr-1"
								type="secondaryOutline"
							></ui-pill>
						}
					</div>
				} @else {
					<span class="text-secondary-400 line-clamp-1">
						{{ 'select.placeholder' | translate }}
					</span>
				}

				<ui-icon
					icon="x_arrow_down"
					size="m"
					class="transition"
					[ngClass]="dropdownOpen ? 'rotate-180' : ''"
				></ui-icon>
			</div>
		</div>

		@if (unions | vehicleMultiSelectOptions: currentSelectedUnion : cachedSelection; as groups) {
			<div *uiDropdownContent class="flex flex-col" #dropdownContent>
				<div class="overflow-y-auto max-h-64 border-secondary-300">
					@if (groups.length === 0) {
						<ui-notify [text]="'general.no_data' | translate"></ui-notify>
					}
					@for (group of groups; track group.name) {
						@if (currentSelectedUnion === group.name) {
							<ng-container
								*ngTemplateOutlet="activeUnionHeader; context: { union: group.name }"
							></ng-container>
						}
						@for (vehicle of group.options; track vehicle.value.serial) {
							<ng-container
								*ngTemplateOutlet="
									vehicleOption;
									context: { option: vehicle, selected: isVehicleSelected(vehicle.value) }
								"
							></ng-container>
						}
					}
				</div>
				<div class="flex items-center border-t border-secondary-300 h-10 w-full justify-center">
					<div
						class="flex items-center text-secondary-800 cursor-pointer text-sm p-2 user-select-none justify-center w-full"
						(click)="onSave()"
					>
						{{ 'general.save' | translate }}
					</div>
				</div>
			</div>
		}
	</div>
</div>

<!-- Option Template -->
<ng-template let-option="option" let-selected="selected" #vehicleOption>
	<div
		class="flex gap-2 h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
		[ngClass]="{
			'bg-primary-100': selected,
			'text-secondary-400': option.isDisabled,
			'cursor-not-allowed': option.isDisabled,
		}"
		(click)="toggleVehicle(option)"
	>
		<ui-checkbox
			[disabled]="option.isDisabled"
			[checked]="selected"
			(checkedChange)="toggleVehicle(option)"
			class="mr-4 -mt-[1.625rem]"
		></ui-checkbox>
		<div class="flex items-center justify-between -ml-4 w-full">
			<div class="flex items-center gap-1">
				<div class="line-clamp-1 break-all max-w-full">
					{{ option.title }}
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- Currently Select Union -->
<ng-template let-union="union" #activeUnionHeader>
	<div
		class="flex gap-2 h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
		(click)="deselectAll()"
		id="vehicle-multi-select-union-header"
	>
		<ui-checkbox
			[icon]="checkIfAllVehiclesFromUnionAreSelected(union) ? 'checkmark' : 'minus'"
			[checked]="true"
			class="mr-4 -mt-[1.625rem]"
		></ui-checkbox>
		<span class="select-none font-semibold text-primary-900 -ml-4">{{ union }}</span>
	</div>
</ng-template>
