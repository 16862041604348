import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '@agilox/common';
import { SelectOption } from '@agilox/ui-common';

@Component({
	selector: 'ui-union-select-option',
	templateUrl: './union-select-option.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnionSelectOptionComponent {
	@Input() union: string = '';

	private _options: SelectOption<Vehicle>[] | null = null;

	@Input() set options(options: SelectOption<Vehicle>[] | null) {
		this._options = options;
	}

	@Input() selectedVehicles: Vehicle[] = [];

	@Output() deselectAll: EventEmitter<void> = new EventEmitter<void>();

	get options(): SelectOption<Vehicle>[] | null {
		return this._options;
	}
}
