import { createFeature, createReducer, on } from '@ngrx/store';
import { setFailureVehicles } from './failures-vehicle-filter.actions';

export interface FailuresVehicleFilter {
	selectedVehicles: string[];
}

const initialState: FailuresVehicleFilter = {
	selectedVehicles: [],
};

const reducer = createReducer(
	initialState,
	on(setFailureVehicles, (state, action) => ({ ...state, selectedVehicles: action.value }))
);

export const failuresVehicleFilterFeature = createFeature({
	name: 'failuresVehicleFilter',
	reducer,
});
