import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { DetailedState } from '../../services/machine/detailed-state';
import { SelectOption } from '@agilox/ui-common';
import { DropdownDirective } from '@agilox/ui';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'agilox-analytics-vehicle-multi-select',
	templateUrl: './vehicle-multi-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleMultiSelectComponent implements OnInit {
	public formControl: FormControl<DetailedState[]> = new FormControl<DetailedState[]>([]);

	@Input() set unions(unions: Array<[string, Array<DetailedState>]>) {
		this._unions = unions;
		this.setDefaultOption();
	}

	private _unions: Array<[string, Array<DetailedState>]> = [];

	get unions(): Array<[string, Array<DetailedState>]> {
		return this._unions;
	}

	@Input() set selectedVehicles(selectedVehicles: Array<DetailedState>) {
		this.formControl.setValue(selectedVehicles, { emitEvent: false });
	}

	@Output() selectedVehiclesChange: EventEmitter<Array<DetailedState>> = new EventEmitter<
		Array<DetailedState>
	>();

	@ViewChild(DropdownDirective) dropdown: DropdownDirective | undefined;

	@ViewChild('dropdownContent') dropdownContent: ElementRef | undefined;

	@Input() set disabled(disabled: boolean) {
		if (disabled) {
			this.formControl.disable();
		} else {
			this.formControl.enable();
		}
	}

	public currentSelectedUnion: string = '';

	public dropdownOpen: boolean = false;

	open() {
		this.dropdown?.openDropdown();
	}

	close() {
		this.dropdown?.closeDropdown();
	}

	/**
	 * If no option is selected, the first option will be chosen automatically
	 */
	setDefaultOption() {
		if (!this.formControl.value?.length && this.unions) {
			const firstUnion = this.unions[0];
			if (firstUnion) {
				const firstOption: DetailedState = firstUnion[1][0];
				this.formControl.setValue([firstOption]);
				this.cachedSelection = [firstOption];
			}
		}
		this.setSelectedUnion(this.formControl.value.length ? this.formControl.value[0]?.union : '');
	}

	setSelectedUnion(union: string) {
		this.currentSelectedUnion = union;
	}

	/**
	 * Need to get the savedValue from the select component
	 * as the formControl is only updated after the save button is clicked
	 * and we need to handle the disabled options immediately
	 */
	public cachedSelection: DetailedState[] | null = null;

	onSave() {
		this.formControl.setValue(this.cachedSelection);
		this.dropdown?.closeDropdown();
	}

	ngOnInit() {
		this.formControl.valueChanges.subscribe((value: DetailedState[]) => {
			this.setSelectedUnion(value[0]?.union ?? '');
			this.selectedVehiclesChange.emit(value);
			this.cachedSelection = value;
		});
	}

	isVehicleSelected(vehicle: DetailedState): boolean {
		return (
			this.cachedSelection?.some(
				(selectedVehicle) => selectedVehicle?.serial === vehicle?.serial
			) ?? false
		);
	}

	toggleVehicle(option: SelectOption<DetailedState>) {
		if (option.isDisabled) {
			return;
		}

		/**
		 * Handles the selection / deselection of a vehicle
		 */
		const vehicle: DetailedState = option.value;
		if (this.isVehicleSelected(vehicle)) {
			this.cachedSelection = this.cachedSelection?.filter(
				(selectedVehicle) => selectedVehicle.serial !== vehicle.serial
			);
		} else {
			this.cachedSelection = [...(this.cachedSelection ?? []), vehicle];
		}

		/**
		 * Set the selected union
		 */
		this.setSelectedUnion(this.cachedSelection?.length ? this.cachedSelection[0].union : '');

		this.scrollToTop();
	}

	onDropdownStateChange(open: boolean) {
		this.dropdownOpen = open;
		this.cachedSelection = JSON.parse(JSON.stringify(this.formControl.value));
		this.setSelectedUnion(this.cachedSelection?.length ? this.cachedSelection[0].union : '');
	}

	checkIfAllVehiclesFromUnionAreSelected(union: string): boolean {
		let unionVehicles: DetailedState[] = this.unions.find((u) => u[0] === union)?.[1] ?? [];
		return unionVehicles.every((unionVehicle) =>
			this.cachedSelection.find((vehicle: DetailedState) => vehicle.serial === unionVehicle.serial)
		);
	}

	deselectAll() {
		this.cachedSelection = [];
		this.currentSelectedUnion = '';
	}

	scrollToTop() {
		setTimeout(() => {
			const unionHeader: HTMLElement | null = this.dropdownContent?.nativeElement.querySelector(
				'#vehicle-multi-select-union-header'
			);
			if (unionHeader) {
				unionHeader.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		});
	}
}
