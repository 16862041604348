import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SelectorService } from 'apps/analytics/src/app/general/shared-desktop/selector/selector.service';
import { SelectorEvent } from 'apps/analytics/src/app/general/shared-desktop/selector/selector-event.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HeaderMenuIcons } from '../../general/enums/header-menu-icons.enum';
import { HeaderMenu } from '../../general/shared-desktop/selector/header-menu.enum';
import { SaveService } from './save/save.service';

@Component({
	selector: 'agilox-analytics-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
	/** header menu icons */
	headerMenuIcons = HeaderMenuIcons;

	/** Used for closing the header on click */
	@ViewChild('topbar') topbar: ElementRef<HTMLDivElement>;

	/** Used for ios fix */
	@ViewChild('content') content: ElementRef<HTMLDivElement>;

	/** holds all header menus */
	headerMenus = Object.keys(HeaderMenu).map((str) => str as HeaderMenu);

	/** active menu */
	activeMenu: HeaderMenu = null;

	private _headerMenuOpen: boolean | boolean = false;

	@Input() set headerMenuOpen(value: boolean) {
		this._headerMenuOpen = value;
		if (this._headerMenuOpen === false) {
			this.closeMenu();
		}
	}

	get headerMenuOpen(): boolean {
		return this._headerMenuOpen;
	}

	@Output() headerMenuOpenChange = new EventEmitter<boolean>();

	private headerServiceSubscription: Subscription;
	private routerSubscription: Subscription;
	private saveSubscription: Subscription;
	private cancelSubscription: Subscription;

	constructor(
		private changeDetection: ChangeDetectorRef,
		private selectorService: SelectorService,
		private saveService: SaveService,
		private router: Router,
		private deviceDetectorService: DeviceDetectorService
	) {}

	ngOnInit(): void {
		this.routerSubscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				this.closeMenu();
			});

		this.headerServiceSubscription = this.selectorService.registerOnSelectorSubject(
			(evt: SelectorEvent) => {
				this.setMenu(evt.menu);
				this.headerHeightForIos(evt.menu);
				this.changeDetection.detectChanges();
			}
		);

		this.saveSubscription = this.saveService.registerOnSave(() => {
			this.toggleMenu(null);
			this.changeDetection.detectChanges();
		});

		this.cancelSubscription = this.saveService.registerOnCancel(() => {
			this.activeMenu = null;
			this.headerHeightForIos(this.activeMenu);
			this.changeDetection.detectChanges();
		});
	}

	ngOnDestroy() {
		this.headerServiceSubscription?.unsubscribe();
		this.routerSubscription?.unsubscribe();
		this.saveSubscription?.unsubscribe();
		this.cancelSubscription?.unsubscribe();
	}

	/**
	 * closes the menu
	 */
	@HostListener('document:keydown.escape', ['$event'])
	closeMenu() {
		this.toggleMenu(null);
		this.saveService.invokeOnCancel();
	}

	/**
	 * toggles the header menu
	 * @param newMenu new menu
	 */
	toggleMenu(newMenu: HeaderMenu) {
		this.activeMenu = this.activeMenu === newMenu ? null : newMenu;

		if (!this.activeMenu) {
			this.saveService.invokeOnCancel();
		}

		this.headerHeightForIos(this.activeMenu);
		this.headerMenuOpenChange.emit(!!this.activeMenu);
	}

	/**
	 * fixes the header height for iOS
	 */
	headerHeightForIos(menu: HeaderMenu) {
		const content = this.content ? this.content.nativeElement : null;

		// ios fix
		if (!this.deviceDetectorService.isDesktop() && menu) {
			const windowHeight = window.innerHeight;
			const headerHeight = 43;
			const bottommenuHeight = 60;
			const bodyHeight = windowHeight - headerHeight - bottommenuHeight;
			if (content) {
				content.style.height = `${bodyHeight}px`;
			}
		}

		if (!this.activeMenu && content) {
			content.style.height = 'auto';
		}
	}

	/**
	 * sets the header menu
	 * @param menu menu
	 */
	setMenu(menu: HeaderMenu) {
		this.activeMenu = menu;
	}

	/**
	 * handles the click event
	 * closes the menu when clicking outside of the header
	 * @param event
	 */
	@HostListener('window:click', ['$event'])
	clickHandler($event: any) {
		if (!$event.target.closest('.ui-dropdown') && this.activeMenu) {
			this.closeMenu();
		}
	}

	clickInsideHeader(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
	}
}
