import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

/**
 * handles the saving and canceling of all settings in selector
 * register on the subject and invoke it
 */
@Injectable({
	providedIn: 'root',
})
export class SaveService {
	private onSaveSubject = new Subject<void>();
	private onCancelSubject = new Subject<void>();

	registerOnSave(callback: () => void): Subscription {
		return this.onSaveSubject.subscribe(callback);
	}

	invokeOnSave() {
		this.onSaveSubject.next();
	}

	registerOnCancel(callback: () => void): Subscription {
		return this.onCancelSubject.subscribe(callback);
	}

	invokeOnCancel() {
		this.onCancelSubject.next();
	}
}
