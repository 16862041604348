import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Entry } from 'apps/analytics/src/app/general/shared/services/chart-data/entry';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { Entry as TimelineEntry } from 'apps/analytics/src/app/general/widgets/components-for-widget-construction/timeline-chart/entry';
import { PerformanceColor } from '@agilox/common';
import { ChartOptions, TooltipItem } from 'chart.js';
import { PartialObserver, Subscription } from 'rxjs';
import { HeaderMenu } from '../../shared-desktop/selector/header-menu.enum';
import { SelectorService } from '../../shared-desktop/selector/selector.service';
import { AppsettingsService } from '../../shared/services/appsettings/appsettings.service';
import { Granularity } from '../../shared/services/appsettings/granularity.enum';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { ChartOptionGenerator } from '../components-for-widget-construction/timeline-chart/chart-configs';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { PerformancePercentageName } from '../../enums/performance-percentage-name.enum';

/**
 * displays the activity of a machine in a stacked line chart
 */
@Component({
	selector: 'agilox-analytics-widget-productivity-history',
	templateUrl: './widget-productivity-history.component.html',
	styleUrls: ['./widget-productivity-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProductivityHistoryComponent extends BaseWidgetDirective implements OnDestroy {
	/**
	 * indicator for rendering the widget
	 * is needed for rerendering after the appsettings changed
	 */
	render = true;
	/**
	 * indicator which menu should be displayed
	 * donuts or bars
	 */
	areBarsVisible: boolean;

	/**
	 * holds the value the bars-chart shouldn't show
	 */
	excludedBarChartValues: Array<string>;

	/**
	 * subscriptions on appsettings
	 */
	appsettingsSubscriptions: Array<Subscription>;
	observer: PartialObserver<any> = {
		next: () => {
			this.setTooltipSpacing();
			this.render = false;
			this.changeDetection.markForCheck();
		},
	};

	config: Array<EntryConfig>;

	unit = '%';

	/** custom body spacing  */
	customBodyOptions = { keys: ['plugins', 'tooltip'], property: 'bodySpacing', value: 6 };

	ownOptions: ChartOptions = {
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
			y: {
				stacked: true,
				beginAtZero: true,
				suggestedMin: 0,
				max: 100,
				min: 0,
				ticks: {
					maxTicksLimit: 5,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
			},
			line: {
				tension: 0,
			},
		},
		plugins: {
			tooltip: {
				position: 'nearest',
				yAlign: 'bottom',
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (tooltipItem: TooltipItem<any>) => {
						const value = (tooltipItem.dataset.data[tooltipItem.dataIndex] * 100) / 100;
						const result = value - Math.floor(value) !== 0 ? value.toFixed(2) : value;
						return (
							this.translate.instant('performance.' + this.config[tooltipItem.datasetIndex].name) +
							': ' +
							result +
							this.unit
						);
					},
					labelColor: (tooltipItem: TooltipItem<any>) => {
						return {
							backgroundColor: PerformanceColor[tooltipItem.dataset.label],
							borderColor: PerformanceColor[tooltipItem.dataset.label],
						};
					},
				},
				itemSort: (a, b) => {
					return b.datasetIndex - a.datasetIndex;
				},
			},
			legend: {
				display: false,
			},
		},
	};

	loadingHandler: LoadingHandler<Machine, ReducedMachine>;

	constructor(
		public appsettings: AppsettingsService,
		private chartService: ChartDataService,
		private translate: TranslateService,
		private changeDetection: ChangeDetectorRef,
		private selectorService: SelectorService
	) {
		super();
		this.loadingHandler = new LoadingHandler<Machine, ReducedMachine>(
			chartService,
			chartService.getData,
			this.transformData.bind(this),
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);

		this.areBarsVisible = false;
		this.excludedBarChartValues = new Array<string>();
		this.appsettingsSubscriptions = new Array<Subscription>();

		this.appsettingsSubscriptions.push(this.appsettings.registerOnMachineSelected(this.observer));
		this.appsettingsSubscriptions.push(this.appsettings.registerOnDateSelector(this.observer));

		this.config = new Array<EntryConfig>();
		Object.keys(PerformanceColor).forEach((key) => {
			this.config.push(new EntryConfig(key, { borderColor: PerformanceColor[key] }));
		});
		this.setLineChartOptions();
		this.setTooltipSpacing();
	}

	/**
	 * unsubscribes all subscriptions
	 */
	ngOnDestroy() {
		this.appsettingsSubscriptions.forEach((sub) => {
			sub?.unsubscribe();
		});
	}

	/**
	 * gets invoked from the select-bar-chart-values when a value was selected to not show up
	 * @param values selected values
	 */
	hideBarChartValues(values: Array<string>) {
		this.excludedBarChartValues = values;
	}

	/**
	 * opens mode selector
	 * @param event mouse event
	 */
	openModeSelector(event: MouseEvent) {
		event.stopImmediatePropagation();
		this.selectorService.openSelector({ menu: HeaderMenu.modes, event });
	}

	/**
	 * sets the line chart options
	 */
	setLineChartOptions() {
		const defaultOptions = ChartOptionGenerator.getDefaultLinechartOptions(
			this,
			undefined,
			undefined,
			undefined
		);
		Object.keys(this.ownOptions).forEach((key) => {
			defaultOptions[key] = this.ownOptions[key];
		});
		this.ownOptions = defaultOptions;
	}

	/**
	 * transforms data
	 * @param data machines data
	 */
	private transformData(data: Array<Machine>): Array<ReducedMachine> {
		if (data[0]?.entries.length > 0) {
			return data.map((machine) => {
				return {
					serial: machine.serial,
					data: machine.entries.map((entry: Entry) => {
						return new Timestamp(entry.start, [
							...Object.keys(PerformancePercentageName).map((key) => {
								return new TimelineEntry(PerformancePercentageName[key], this.safe(entry[key]));
							}),
						]);
					}),
				};
			});
		} else {
			return new Array<ReducedMachine>();
		}
	}

	/**
	 * rounds number
	 * @param data number
	 */
	private safe(data: number): number {
		return data === undefined ? 0 : Math.round(data * 100) / 100;
	}

	/**
	 * exports csv
	 */
	exportCsv() {
		this.chartService.exportStats();
	}

	/**
	 * set the tooltip spacing
	 * TODO: find a solution for the spacing between x axis and tooltip
	 * then the method can be removed
	 */
	setTooltipSpacing() {
		if (this.appsettings.dateSelector.granularity === Granularity.dd) {
			this.ownOptions.plugins.tooltip.bodySpacing = 5;
		} else {
			this.ownOptions.plugins.tooltip.bodySpacing = -1;
		}
	}
}
