<div class="header" #topbar (click)="clickInsideHeader($event)">
	<div class="header__container">
		<div
			class="header__item header__item--{{ entry }}"
			*ngFor="let entry of headerMenus"
			(click)="toggleMenu(entry)"
			[class.is-active]="entry === activeMenu"
			[attr.data-cy]="'header-' + entry"
		>
			<span class="header__text hide-mobile">
				{{ 'general.header.' + entry | translate }}
			</span>
			<ui-icon class="hide-desktop" [icon]="headerMenuIcons[entry]" size="m" type="dark"></ui-icon>
			<ui-icon class="header__chevron" icon="x_arrow_down" size="l" type="dark"></ui-icon>
		</div>
		<div class="header__content" [ngClass]="{ 'is-open': activeMenu }" #content>
			<agilox-analytics-machine-selector
				class="header__selector"
				[inView]="activeMenu === 'machines'"
				[class.is-hidden]="activeMenu !== 'machines'"
			>
			</agilox-analytics-machine-selector>
			<agilox-analytics-mode-selector
				class="header__selector"
				[class.is-hidden]="activeMenu !== 'modes'"
			>
			</agilox-analytics-mode-selector>
			<agilox-analytics-date-selector
				class="header__selector"
				[class.is-hidden]="activeMenu !== 'calendar'"
			>
			</agilox-analytics-date-selector>
		</div>
	</div>
</div>
