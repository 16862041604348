<div class="input-button" [ngClass]="{ 'input-button--disabled': disabled }">
	<div class="input-button__label" *ngIf="label">
		<span>{{ label }}</span>
	</div>

	<div class="input-button__input" (click)="inputClicked.emit()">
		<div class="input-button__inputField">
			<span class="input-button__value">{{ value }}</span>
		</div>
	</div>
</div>
