import { SecondsPipe } from 'libs/pipe/seconds/src/lib/pipe-seconds.pipe';
import { ActionGroup } from './action-group';

/**
 * represents an Action
 */
export class Action {
	/**
	 * constructs an Action
	 * @param id name
	 * @param skip skip
	 * @param start start
	 * @param end end
	 * @param duration duration
	 * @param station station
	 * @param agiloxName agilox name
	 * @param serial serial
	 * @param workflowName workflow name
	 * @param workflow workflow
	 * @param icon icon
	 */
	constructor(
		public name: string,
		public skip: boolean,
		public start: number,
		public end: number,
		public duration: number,
		public station: string,
		public agiloxName: string,
		public serial: string,
		public workflowName: string,
		public workflow: string,
		public icon: string
	) {}

	/**
	 * generates order out of the MachineMgmt/actions request
	 * @param data entry of the MachineMgmt/actions
	 */
	static generateFromJson(data: any): Action | ActionGroup {
		return new Action(
			data.action,
			data.skip,
			data.start,
			data.end,
			new SecondsPipe().transform(data.duration, true),
			data.station ? data.station : '',
			data.agilox_name,
			data.serialnumber,
			data.workflow_name,
			data.order_id,
			this.getActionIcon(data.action)
		);
	}

	/**
	 * return the action icon name
	 * @param action action type name
	 */
	static getActionIcon(action: string): string {
		switch (action) {
			case 'pickup':
				return 'o_arrow_up';
			case 'drop':
				return 'o_arrow_down';
			case 'drop_here':
				return 'o_arrow_down';
			case 'charge':
				return 'charge';
			case 'discharge':
				return 'charge';
			case 'drive':
				return 'position';
			case 'drive_over':
				return 'position';
			case 'fire':
				return 'fire';
			case 'park':
				return 'park';
			case 'wait':
				return 'clock';
			case 'supply':
				return 'o_plus';
			case 'dispose':
				return 'o_minus';
			case 'carrier_pickup':
				return 'carrier_pickup';
			case 'reference_lift_current':
				return 'weight_zero';
			case 'leave_station':
				return 'o_arrows_right';
			default:
				return '';
		}
	}
}
