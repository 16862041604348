import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	QueryList,
	Renderer2,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { StationType } from '../../../enums/station-type.enum';
import { Station } from '../../services/failure-order-workflow/station';
import { Floor } from '../../services/machine/floor.type';

@Component({
	selector: 'agilox-analytics-station',
	templateUrl: './station.component.html',
	styleUrls: ['./station.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationComponent implements AfterViewInit, OnChanges {
	/** counter for the station boxes in the html */
	stationBoxCounter = new Array<number>();

	/** station name for the tooltip */
	stationName: string;

	/** staion */
	private _station: Station;
	@Input() set station(value: Station) {
		this._station = value;

		// is used to draw the stations in html
		if (this._station.dollyCount > 0 && this._station.type === StationType.RAIL) {
			for (let i = 0; i < this._station.dollyCount; i++) {
				this.stationBoxCounter.push(i);
			}
		} else {
			this.stationBoxCounter = [0];
		}

		// sets the station name for the tooltip
		this.stationName = this._station.name;
	}
	get station(): Station {
		return this._station;
	}

	/** selected floor in the map  */
	@Input() floor: Floor;

	/** scale of the map */
	@Input() scale: number;

	/** height of the map */
	@Input() mapHeight: number;

	@Input() highlightErrors: boolean = false;

	/** station div */
	@ViewChild('station') stationDiv: ElementRef<HTMLDivElement>;

	/** station box or boxes */
	@ViewChildren('stationBoxes') stationBoxes: QueryList<ElementRef<HTMLDivElement>>;

	constructor(
		private renderer: Renderer2,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if ((changes['mapHeight'] || changes['scale']) && this.stationDiv) {
			this.renderStation();
		}
	}

	ngAfterViewInit(): void {
		this.renderStation();
	}

	/**
	 * renders the stations and sets the correct coordinates
	 */
	renderStation() {
		const nativeElement = this.stationDiv.nativeElement;
		this.renderer.setStyle(nativeElement, 'transform', 'scale(' + this.scale + ')');
		this.renderer.setStyle(nativeElement, '-webkit-transform', 'scale(' + this.scale + ')');

		if (this.floor[0] === this.station.floor) {
			const x = (this.station.x - this.station.width / 2) * this.scale;
			const y = (this.mapHeight - this.station.y - this.station.length / 2) * this.scale;

			this.renderer.setStyle(nativeElement, 'width', this.station.width * this.scale + 'px');
			this.renderer.setStyle(nativeElement, 'height', this.station.length * this.scale + 'px');

			if (this.station.type === StationType.RAIL) {
				this.renderRail();
			}

			this.renderer.setStyle(nativeElement, 'left', x + 'px');
			this.renderer.setStyle(nativeElement, 'top', y + 'px');
			nativeElement.style.transform = 'rotate(' + this.station.angle * -1 + 'deg)';
			this.renderer.setStyle(nativeElement, 'visibility', 'visible');
		} else {
			this.renderer.setStyle(nativeElement, 'visibility', 'hidden');
		}
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * renders the rail station
	 */
	renderRail() {
		this.stationBoxes.map((station, index) => {
			const element = station.nativeElement;
			element.style.width = this.station.width * this.scale + 'px';
			element.style.height = this.station.length * this.scale - 2 + 'px';
			element.style.marginTop = index > 0 ? '2px' : '';
			return station;
		});
	}
}
