import { App, ErrorService } from '@agilox/common';
import { environment } from '@analytics/env/environment';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { AppsettingsService } from '../shared/services/appsettings/appsettings.service';
import { ErrorType } from '@agilox/common';

/**
 * handles network errors
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	/**
	 *
	 * @param router
	 * @param errorService
	 * @param authentication
	 * @param appsettingsService
	 */
	constructor(
		private router: Router,
		private errorService: ErrorService,
		private authentication: AuthenticationService,
		private appsettingsService: AppsettingsService
	) {}

	/**
	 * checks on every request (if not excluded in the environment files), if a request fails it opens up a popup
	 * @param request
	 * @param next
	 */
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (this.isExcluded(error.url)) {
					return of(new HttpResponse({ status: error.status, body: {} }));
				}
				return this.handleNetworkError(request, error);
			}),
			filter((event) => event instanceof HttpResponse),
			tap((evt: HttpEvent<any>) => {
				// checking the status of the response
				if (evt instanceof HttpResponse) {
					if (
						evt.body &&
						evt.body.msg === 'authentication failed' &&
						this.authentication.isLoggedIn
					) {
						this.authentication.isLoggedIn = false;
						this.router.navigateByUrl('login?error=session');
					}
				}
			}),
			tap((response: HttpResponse<any>) => {
				if (response.body.status === false && !this.isExcluded(request.url)) {
					this.errorService.setError({
						application: App.analytics,
						url: request.url,
						user: this.appsettingsService.user,
						type: ErrorType.NETWORK_ERROR,
						message: response.body.msg,
					});
				}
			}),
			catchError((error) => {
				if (!navigator.onLine) {
					this.errorService.setError({
						application: App.analytics,
						url: request.url,
						user: this.appsettingsService.user,
						type: ErrorType.NO_INTERNET_ERROR,
					});
				}
				return throwError(() => error);
			})
		);
	}

	/**
	 * checks if the url is excluded in the environment files
	 * @param url
	 */
	private isExcluded(url: string): boolean {
		return (
			!!environment.excludedRoutes.find((route) => environment.server + route === url) ||
			!!environment.excludedRoutePatterns.find((route) => url.includes(route))
		);
	}

	private handleNetworkError(req: HttpRequest<any>, error: any): Observable<any> {
		this.errorService.setError({
			application: App.analytics,
			user: this.appsettingsService.user,
			message: error.message,
			url: req.url,
			type: ErrorType.NETWORK_ERROR,
		});
		return of(new HttpResponse({ body: {} }));
	}
}
