import { Failure } from 'apps/analytics/src/app/general/shared/services/failure-order-workflow/failure';

export class FailureCategory {
	/**
	 * @param name category name
	 * @param failures failures with the category
	 */
	constructor(
		public name: string,
		public failures: Array<Failure>,
		public collapsed: boolean
	) {
		if (!failures) {
			this.failures = new Array<Failure>();
		}
		this.failures = failures;
	}

	/**
	 * generates a failure category object from json
	 * @param data data
	 * @param key name of failure category
	 */
	static generateFromJson(data: Array<any>, key: string): FailureCategory {
		const failures = data
			.map((element) => {
				return Failure.generateFromJson(element);
			})
			.sort((a: Failure, b: Failure) => Number(a.index) - Number(b.index));
		return new FailureCategory(key, failures, false);
	}
}
