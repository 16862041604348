import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SaveService } from './save/save.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SubscriptionHandler implements OnDestroy {
	private saveSubscription: Subscription;
	private cancelSubscription: Subscription;

	constructor(private saveService: SaveService) {
		this.saveSubscription = saveService.registerOnSave(() => this.save());
		this.cancelSubscription = saveService.registerOnCancel(() => this.cancel());
	}

	ngOnDestroy() {
		this.saveSubscription?.unsubscribe();
		this.cancelSubscription?.unsubscribe();
	}

	invokeSave() {
		this.saveService.invokeOnSave();
	}

	invokeCancel() {
		this.saveService.invokeOnCancel();
	}

	abstract save();

	abstract cancel();
}
