import { HeaderMachine } from './header-machine';

export class HeaderUnion extends HeaderMachine {
	constructor(
		public override serial: string,
		public override shortSerial: string,
		public override name: string,
		public override emails: Array<string>,
		public override union: string,
		public override unionId: string,
		public override shortUnionId: string,
		public override company: string,
		public override selected: boolean,
		public serials: string[],
		public override city: string
	) {
		super(
			serial,
			shortSerial,
			name,
			emails,
			union,
			unionId,
			shortUnionId,
			company,
			false,
			selected,
			city,
			'',
			[],
			false
		);
	}
}
