import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FailuresSubMenuUrl } from '../../shared/enums/failures-submenu.enum';
import { Failure } from '../../shared/services/failure-order-workflow/failure';
import { FailureOrderWorkflowService } from '../../shared/services/failure-order-workflow/failure-order-workflow.service';
import { Col } from '../components-for-widget-construction/table-chart/col.type';
import { HeaderIconsDisabled } from '../components-for-widget-construction/table-chart/header-icons-disabled.type';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

@Component({
	selector: 'agilox-analytics-widget-failures-technical',
	templateUrl: './widget-failures-technical.component.html',
	styleUrls: ['./widget-failures-technical.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFailuresTechnicalComponent extends BaseWidgetDirective {
	config: Array<Col>;

	/**
	 * constructs the component
	 */
	constructor(
		private service: FailureOrderWorkflowService,
		private router: Router
	) {
		super();
		this.config = [
			['widget.failures_quit.failure', 'description'],
			['widget.failures_quit.count', 'count'],
		];
	}

	getFetchFunction() {
		return this.service.getFailuresForWidgets.bind(this.service);
	}

	parseFailures(data: Array<Failure>): Array<{ description: string; duration: string }> {
		//Filter technical issues only
		data = data.filter((failure) => failure.process_failure === false);

		// count equal technical issues
		const result = [
			...data
				.reduce((wholeMap, failure) => {
					const k = failure.description;
					// contains the map already the description
					if (!wholeMap.has(k)) {
						wholeMap.set(k, { ...failure, count: 1 });
					} else {
						wholeMap.get(k).count++;
					}
					wholeMap.get(k).tooltip = 'failures.' + failure.type;
					return wholeMap;
				}, new Map() /* initial value */)
				.values(),
		];

		// Return top technical issues
		return result.sort((a, b) => b.count - a.count);
	}

	/**
	 * Routes to the failure-analysis and passes the selected failure from the table
	 * @param failure clicked failure from the table
	 */
	clickedOnFailure(failure: Failure) {
		this.router.navigateByUrl(FailuresSubMenuUrl.visualization, {
			state: { groupFailure: failure, process_failure: false },
		});
	}

	/**
	 * gets invoked when header icons disabled changed
	 * @param event header icon disabled type
	 */
	headerDisabledChanged(event: HeaderIconsDisabled) {
		this.disabledOnLoadNoData = event[0];
		this.disableOnLoad = event[1];
	}
}
