import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { DateSelector } from 'apps/analytics/src/app/general/shared/services/appsettings/date-selector.interface';
import { Granularity } from 'apps/analytics/src/app/general/shared/services/appsettings/granularity.enum';
import { SaveService } from '../save/save.service';
import { SubscriptionHandler } from '../subscription-handler';
import { WeekDay } from './weekdays.enum';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { convertToUTCDate } from '@agilox/common';
import { GranularityMode } from './granularity-mode.interface';
import { Store } from '@ngrx/store';
import { dateFilterFeature } from '../../../store/date-filter/date-filter.state';

@Component({
	selector: 'agilox-analytics-date-selector',
	templateUrl: './date-selector.component.html',
	styleUrls: ['./date-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorComponent extends SubscriptionHandler implements OnInit, OnDestroy {
	/** selected starting day in the calendar */
	startDay: number;
	/** left calendar gets set to this month/year */
	timestampToShowLeft: number;
	/** selected ending day in the calendar */
	endDay: number;
	/** right calendar gets set to this month/year */
	timestampToShowRight: number;

	/** holds all date modes */
	dateModes: Array<{ translation: string; description: string; name: string }>;
	/** current date mode */
	currentDateMode: { translation: string; name: string };

	/** holds all granularity modes */
	granularityModes: Array<GranularityMode>;
	/** current granularity */
	currentGranularity: GranularityMode;
	/** max value per granularity (2days, 120days, 1year,  1year,  1year) */
	maxPerGranularity = [
		1000 * 60 * 60 * 24 * 2,
		1000 * 60 * 60 * 24 * 120,
		1000 * 60 * 60 * 24 * 365,
		1000 * 60 * 60 * 24 * 365,
		1000 * 60 * 60 * 24 * 365,
	];
	/** min value in milliseconds per granularity (>1min, >2h, >1d, >1 week, >31 days) */
	minPerGranularity = [60001, 7200001, 86400001, 604800001, 2764800000];

	/** is the granularity set to day */
	get granularityDaySelected(): boolean {
		return this.currentGranularity.label === 'general.datepicker.granularity.day.day';
	}

	/** is the granularity set to week */
	get granularityWeekSelected(): boolean {
		return this.currentGranularity.label === 'general.datepicker.granularity.week.week';
	}

	/** is the granularity set to day */
	get granularityMonthSelected(): boolean {
		return this.currentGranularity.label === 'general.datepicker.granularity.month.month';
	}

	/** time of the start day */
	timeStartDay: number;
	/** time of the end day */
	timeEndDay: number;

	/** is shift start time valid */
	shiftStartValid = true;
	/** is shift end time valid */
	shiftEndValid = true;

	/** is start time valid */
	startTimeValid = true;
	/** is end time valid */
	endTimeValid = true;

	isEndTimeBeforeStartTime = false;

	shiftButtons = [
		{ value: true, label: 'general.on' },
		{ value: false, label: 'general.off' },
	];

	shiftButtonControl: FormControl<boolean> = new FormControl();
	/** shift toggler is on */
	shiftOn: boolean;

	granularityFormControl = new FormControl();

	/** selected shift days */
	selectedShiftDays = new FormControl();

	/** time of the shift start */
	shiftStart: number;
	/** time of the shift end */
	shiftEnd: number;

	/** Indices of Weekday enum transformed to fit button-toggles type */
	days = Object.values(WeekDay)
		.filter((key) => typeof key === 'number')
		.map((key) => ({
			value: key.toString(),
			label: 'general.datepicker.weekdays.' + key.toString(),
		}));

	constructor(
		private appsettings: AppsettingsService,
		saveService: SaveService,
		private changeDetector: ChangeDetectorRef,
		private datePipe: DatePipe,
		private translate: TranslateService,
		private store: Store
	) {
		super(saveService);
		this.timestampToShowLeft = this.appsettings.dateSelector.startDate;
		this.timestampToShowRight = this.appsettings.dateSelector.endDate;

		const dateFormat = { withMinutes: 'dd.MM.yy HH:mm', withoutMinutes: 'dd.MM.yy' };
		const userTimezoneOffsetMinutes = new Date().getTimezoneOffset();
		const currentDate = new Date();
		//today
		const today = this.datePipe.transform(
			new Date(currentDate.getTime() - userTimezoneOffsetMinutes * 60000),
			dateFormat.withoutMinutes,
			'UTC'
		);
		//24 hours
		const oneDayAgo = new Date(currentDate.getTime() - 23 * 60 * 60 * 1000);
		const hours24 =
			this.datePipe.transform(oneDayAgo, dateFormat.withMinutes, 'UTC') +
			' - ' +
			this.datePipe.transform(
				new Date(currentDate.getTime() - userTimezoneOffsetMinutes * 60000),
				dateFormat.withMinutes,
				'UTC'
			);
		//last week
		const weekDate = new Date();
		const toLastWeek = weekDate.setTime(
			weekDate.getTime() - (weekDate.getDay() ? weekDate.getDay() : 7) * 24 * 60 * 60 * 1000
		);
		const fromLastWeek = weekDate.setTime(weekDate.getTime() - 6 * 24 * 60 * 60 * 1000);
		const lastWeek =
			this.datePipe.transform(fromLastWeek, dateFormat.withoutMinutes, 'UTC') +
			' - ' +
			this.datePipe.transform(toLastWeek, dateFormat.withoutMinutes, 'UTC');

		//last month
		const monthFromDate = new Date();
		monthFromDate.setDate(1);
		monthFromDate.setMonth(monthFromDate.getMonth() - 1);
		const monthToDate = new Date();
		monthToDate.setMonth(monthToDate.getMonth(), 0);
		const lastMonth =
			this.datePipe.transform(monthFromDate, dateFormat.withoutMinutes, 'UTC') +
			' - ' +
			this.datePipe.transform(monthToDate, dateFormat.withoutMinutes, 'UTC');

		//7days
		const date7Ago = new Date();
		date7Ago.setDate(date7Ago.getDate() - 7);
		const date7AgoEnd = new Date();
		date7AgoEnd.setDate(date7AgoEnd.getDate() - 1);
		const days7ago =
			this.datePipe.transform(date7Ago, dateFormat.withoutMinutes, 'UTC') +
			' - ' +
			this.datePipe.transform(date7AgoEnd, dateFormat.withoutMinutes, 'UTC');

		//30days
		const date30Ago = new Date();
		date30Ago.setDate(date30Ago.getDate() - 30);
		const date30AgoEnd = new Date();
		date30AgoEnd.setDate(date30AgoEnd.getDate() - 1);
		const days30ago =
			this.datePipe.transform(date30Ago, dateFormat.withoutMinutes, 'UTC') +
			' - ' +
			this.datePipe.transform(date30AgoEnd, dateFormat.withoutMinutes, 'UTC');

		this.dateModes = [
			{
				translation: 'general.datepicker.date_mode.today',
				description: today,
				name: 'today',
			},
			{
				translation: 'general.datepicker.date_mode.hours24',
				description: hours24,
				name: 'hours24',
			},
			{
				translation: 'general.datepicker.date_mode.last_week',
				description: lastWeek,
				name: 'last_week',
			},
			{
				translation: 'general.datepicker.date_mode.last_month',
				description: lastMonth,
				name: 'last_month',
			},
			{
				translation: 'general.datepicker.date_mode.7days',
				description: days7ago,
				name: '7',
			},
			{
				translation: 'general.datepicker.date_mode.30days',
				description: days30ago,
				name: '30',
			},
		];

		this.granularityModes = [
			{
				value: 'minute',
				label: 'general.datepicker.granularity.minute.minute',
				tooltipDisabled: '',
				tooltip: 'general.datepicker.granularity.minute.tooltip',
				granularityLabel: 'general.datepicker.granularity.minute.granularity',
				disabled: false,
			},
			{
				value: 'hour',
				label: 'general.datepicker.granularity.hour.hour',
				tooltipDisabled: '',
				tooltip: 'general.datepicker.granularity.hour.tooltip',
				granularityLabel: 'general.datepicker.granularity.hour.granularity',
				disabled: false,
			},
			{
				value: 'day',
				label: 'general.datepicker.granularity.day.day',
				tooltipDisabled: '',
				tooltip: 'general.datepicker.granularity.day.tooltip',
				granularityLabel: 'general.datepicker.granularity.day.granularity',
				disabled: false,
			},
			{
				value: 'week',
				label: 'general.datepicker.granularity.week.week',
				tooltipDisabled: '',
				tooltip: 'general.datepicker.granularity.week.tooltip',
				granularityLabel: 'general.datepicker.granularity.week.granularity',
				disabled: false,
			},
			{
				value: 'month',
				label: 'general.datepicker.granularity.month.month',
				tooltipDisabled: '',
				tooltip: 'general.datepicker.granularity.month.tooltip',
				granularityLabel: 'general.datepicker.granularity.month.granularity',
				disabled: false,
			},
		];
	}

	ngOnInit() {
		this.resetDatePicker();
		//remove when date selector gets removed
		//Necessary to sync old date selector with new date filter
		this.store
			.select(dateFilterFeature.selectGlobalDateFilterState)
			.subscribe((_) => this.resetDatePicker());
	}

	/**
	 * resets the date picker
	 */
	resetDatePicker() {
		if (this.appsettings.webAppSettings.dateSelector.granularity === 'mm') {
			this.setGranularity(this.granularityModes[0]);
		} else if (this.appsettings.webAppSettings.dateSelector.granularity === 'hh') {
			this.setGranularity(this.granularityModes[1]);
		} else if (this.appsettings.webAppSettings.dateSelector.granularity === 'dd') {
			this.setGranularity(this.granularityModes[2]);
		} else if (this.appsettings.webAppSettings.dateSelector.granularity === 'ww') {
			this.setGranularity(this.granularityModes[3]);
		} else if (this.appsettings.webAppSettings.dateSelector.granularity === 'MM') {
			this.setGranularity(this.granularityModes[4]);
		}

		this.startDay = this.appsettings.webAppSettings.dateSelector.startDate;
		this.timestampToShowLeft = this.startDay;
		this.endDay = this.appsettings.webAppSettings.dateSelector.endDate;
		this.timestampToShowRight = this.endDay;

		this.setTimeStartDay();
		this.setTimeEndDay();

		this.setShift(JSON.parse(JSON.stringify(this.appsettings.webAppSettings.dateSelector)));
		this.checkGranularity();
	}

	/**
	 * applies the selection
	 */
	save() {
		if (
			((this.shiftOn && this.shiftStartValid && this.shiftEndValid) ||
				(!this.shiftOn && this.startTimeValid && this.endTimeValid)) &&
			this.startDay &&
			this.endDay
		) {
			let granularity = Granularity.hh;
			if (
				this.currentGranularity &&
				this.currentGranularity.label === 'general.datepicker.granularity.minute.minute'
			) {
				granularity = Granularity.mm;
			} else if (
				this.currentGranularity &&
				this.currentGranularity.label === 'general.datepicker.granularity.day.day'
			) {
				granularity = Granularity.dd;
			} else if (
				this.currentGranularity &&
				this.currentGranularity.label === 'general.datepicker.granularity.week.week'
			) {
				granularity = Granularity.ww;
			} else if (
				this.currentGranularity &&
				this.currentGranularity.label === 'general.datepicker.granularity.month.month'
			) {
				granularity = Granularity.MM;
			}
			const newSettings: DateSelector = {
				startDate:
					granularity === Granularity.dd
						? this.getFullDate(this.startDay, 0)
						: this.getFullDate(this.startDay, this.timeStartDay),
				endDate:
					granularity === Granularity.dd
						? this.getFullDate(this.endDay, 86340000)
						: this.getFullDate(this.endDay, this.timeEndDay),
				granularity: granularity,
				mode: this.appsettings.dateSelector.mode,
				shiftOn: this.shiftOn,
				shiftStart: this.shiftStart,
				shiftEnd: this.shiftEnd,
				shiftDays: this.selectedShiftDays.value.map((index: string) => WeekDay[index]),
			};
			const same = Object.keys(newSettings).every((key) => {
				if (key !== 'shiftDays') {
					return this.appsettings.dateSelector[key] === newSettings[key];
				} else {
					return (
						this.appsettings.dateSelector.shiftDays.length === newSettings.shiftDays.length &&
						this.appsettings.dateSelector.shiftDays.every((day) =>
							newSettings.shiftDays.includes(day)
						)
					);
				}
			});

			if (!same) {
				this.appsettings.saveDateSelector(newSettings);
			}
		}
		this.timestampToShowLeft = this.appsettings.dateSelector.startDate;
		this.timestampToShowRight = this.appsettings.dateSelector.endDate;
	}

	/**
	 * closes the component
	 */
	cancel() {
		this.resetDatePicker();
		this.changeDetector.detectChanges();
	}

	/**
	 * gets invoked when a start day is selected in the calendar
	 * (resets the endday)
	 * @param startDay
	 */
	setStartDayResetEndDay(startDay: number) {
		this.startDay = startDay;
		this.endDay = null;
		this.checkGranularity();
	}

	onShiftChange(value: boolean) {
		this.shiftOn = value;
	}

	/**
	 * Searches through the granularityModes array and sets it accordingly
	 * @param granularity
	 */
	onGranularityChange(granularity: string) {
		const index = this.granularityModes.findIndex((v) => v.value === granularity);
		this.setGranularity(this.granularityModes[index]);
	}

	/**
	 * gets invoked when an end day is selected in the calendar
	 * @param endDay
	 */
	setEndDay(endDay: number) {
		this.endDay = endDay;
		this.checkEndTimeValid();
		this.checkGranularity();
	}

	/**
	 * gets invoked when custom, 1day, .... gets clicked
	 * sets the start and end according to the selection
	 * @param mode the selected-daymode
	 */
	dateModeSelected(mode: { translation: string; name: string }) {
		this.currentDateMode = mode;
		const date = new Date();
		if (mode.name === 'today') {
			this.startDay = date.getTime();
			this.endDay = date.getTime();
			date.setUTCHours(0, 0, 0);
			this.timeStartDay = (date.getUTCHours() * 60 + date.getUTCMinutes()) * 60 * 1000;
			date.setUTCHours(23, 59, 0);
			this.timeEndDay = (date.getUTCHours() * 60 + date.getUTCMinutes()) * 60 * 1000;
			this.setGranularity(this.granularityModes[1]);
		} else if (mode.name === 'hours24') {
			const userTimezoneOffsetMinutes = new Date().getTimezoneOffset();
			this.startDay = date.getTime() - 23 * 60 * 60 * 1000;
			this.endDay = date.getTime() - userTimezoneOffsetMinutes * 60000;
			const time = (date.getHours() * 60 + date.getMinutes()) * 60 * 1000;
			this.setGranularity(this.granularityModes[1]);
			this.setTimeStartDay();
			this.setTimeEndDay();
		} else if (mode.name === 'last_week') {
			const to = date.setTime(
				date.getTime() - (date.getDay() ? date.getDay() : 7) * 24 * 60 * 60 * 1000
			);
			const from = date.setTime(date.getTime() - 6 * 24 * 60 * 60 * 1000);
			this.startDay = from;
			this.endDay = to;
			this.setGranularity(this.granularityModes[2]);
		} else if (mode.name === 'last_month') {
			const lastdayoflastmonth = new Date();
			lastdayoflastmonth.setMonth(lastdayoflastmonth.getMonth(), 0);
			const firstdayoflastmonth = new Date();
			firstdayoflastmonth.setDate(1);
			firstdayoflastmonth.setMonth(firstdayoflastmonth.getMonth() - 1);
			this.startDay = firstdayoflastmonth.getTime();
			this.endDay = lastdayoflastmonth.getTime();
			this.setGranularity(this.granularityModes[2]);
		} else {
			const startDate = new Date();
			this.startDay = startDate.setUTCDate(startDate.getUTCDate() - Number.parseInt(mode.name));
			const endDate = new Date();
			this.endDay = endDate.setUTCDate(endDate.getUTCDate() - 1);
			this.setGranularity(this.granularityModes[2]);
		}
		this.timestampToShowLeft = this.startDay;
		this.timestampToShowRight = this.endDay;
		this.checkGranularity();
	}

	setGranularity(granularity: GranularityMode) {
		this.granularityFormControl.setValue(granularity.value, { emitEvent: false });
		this.currentGranularity = granularity;
	}

	/**
	 * checks if the selected time is under the maximum
	 */
	isGranularityToBigForShift(): boolean {
		const month = 1000 * 60 * 60 * 24 * 31;
		if (
			this.shiftOn &&
			this.getFullDate(this.endDay, this.timeEndDay) -
				this.getFullDate(this.startDay, this.timeStartDay) >
				month
		) {
			return true;
		}
		return false;
	}

	/**
	 * checks if the selected time is under the maximum for the granularity
	 * @param idx index
	 * @param notCheckCurrent is true when not want to check current granularity
	 */
	isGranularityToBigForSelectedTime(idx: number, notCheckCurrent?: boolean): boolean {
		return (
			(notCheckCurrent ?? this.currentGranularity === this.granularityModes[idx]) &&
			this.getFullDate(this.endDay, this.timeEndDay) -
				this.getFullDate(this.startDay, this.timeStartDay) >
				this.maxPerGranularity[idx]
		);
	}

	/**
	 * checks if the selected time is over the minimum for the granularity
	 * @param idx index
	 * @param notCheckCurrent is true when not want to check current granularity
	 */
	isGranularityToSmallForSelectedTime(idx: number, notCheckCurrent?: boolean): boolean {
		return (
			(notCheckCurrent ?? this.currentGranularity === this.granularityModes[idx]) &&
			this.getFullDate(this.endDay, this.timeEndDay) -
				this.getFullDate(this.startDay, this.timeStartDay) <
				this.minPerGranularity[idx]
		);
	}

	isRangeInvalid(idx: number): boolean {
		if (this.granularityModes[idx].value === 'month') {
			return this.checkMonthGranularity();
		}
		if (this.granularityModes[idx].value === 'week') {
			return this.checkWeekGranularity();
		}
		return false;
	}

	/**
	 * In order for the month granularity to be valid,
	 * the start date must be the first day of the month
	 * and the end date must be the last day of the month
	 * @private
	 */
	private checkMonthGranularity(): boolean {
		const startDate = new Date(this.startDay);
		const nextDayAfterEnd = new Date(this.endDay);
		nextDayAfterEnd.setDate(nextDayAfterEnd.getDate() + 1);
		return startDate.getDate() !== 1 || nextDayAfterEnd.getDate() !== 1;
	}

	private checkWeekGranularity(): boolean {
		const startDate = convertToUTCDate(this.startDay);
		const endDate = convertToUTCDate(this.endDay);
		return startDate.getDay() !== 1 || endDate.getDay() !== 0;
	}

	/** checks if the selected granularity matches the selected timespan */
	checkValidityOfGranularities(): boolean {
		return [0, 1, 2, 3, 4].every(
			(idx) =>
				!this.isGranularityToBigForSelectedTime(idx) &&
				!this.isGranularityToSmallForSelectedTime(idx)
		);
	}

	/**
	 * iterates through the granularity modes array and checks if granularity is valid and sets the correct disabled tooltip text
	 */
	checkGranularity() {
		this.granularityModes = this.granularityModes.map((granularity, idx) => {
			const isGranularityTooBig = this.isGranularityToBigForSelectedTime(idx, true);
			const isGranularityTooSmall = this.isGranularityToSmallForSelectedTime(idx, true);
			let isRangeInvalid: boolean = this.isRangeInvalid(idx);
			granularity.disabled =
				(isGranularityTooBig || isGranularityTooSmall || isRangeInvalid) && this.endDay !== null;

			if (granularity.disabled) {
				granularity.tooltipDisabled = this.getDisabledGranularityTooltip(
					granularity,
					idx,
					isGranularityTooBig
				);
			}
			return granularity;
		});
		/**
		 * If the current granularity is disabled, set the current granularity to the first non-disabled granularity
		 */
		let currentGranularity = this.granularityModes.find(
			(granularity) => granularity.value === this.currentGranularity.value
		);
		if (currentGranularity && currentGranularity.disabled) {
			this.setGranularity(this.granularityModes.find((granularity) => !granularity.disabled));
		}
	}

	getDisabledGranularityTooltip(
		granularity: GranularityMode,
		idx: number,
		tooBig: boolean
	): string {
		const key: string = 'dateselector.error.' + (tooBig ? 'maximum' : 'minimum');
		const granularityKey: string = this.translate.instant(granularity.granularityLabel);
		const valueKey: string =
			'dateselector.error.' + (tooBig ? 'maximumValues.' : 'minimumValues.') + idx.toString();

		const translation = this.translate.instant(key, {
			granularity: granularityKey,
			value: this.translate.instant(valueKey),
		});
		if (granularity.value === 'month' || granularity.value === 'week') {
			return (
				translation +
				' ' +
				this.translate.instant('dateselector.error.minimum_extra.' + granularity.value)
			);
		}

		return translation;
	}

	/**
	 * sets the time of the start day
	 */
	setTimeStartDay() {
		const date = new Date(this.startDay);
		this.timeStartDay = date.getUTCHours() * 3600 * 1000 + date.getUTCMinutes() * 60 * 1000;
	}

	/**
	 * sets the time of the end day
	 */
	setTimeEndDay() {
		const date = new Date(this.endDay);
		this.timeEndDay = date.getUTCHours() * 3600 * 1000 + date.getUTCMinutes() * 60 * 1000;
	}

	checkEndTimeValid() {
		if (
			this.datePipe.transform(this.endDay, 'dd.MM.YYYY', 'UTC') ===
			this.datePipe.transform(this.startDay, 'dd.MM.YYYY', 'UTC')
		) {
			this.isEndTimeBeforeStartTime = this.timeEndDay < this.timeStartDay;
		} else {
			this.isEndTimeBeforeStartTime = false;
		}
	}

	/**
	 * returns the date with the time
	 * @param day day
	 * @param milliseconds time
	 */
	getFullDate(day: number, milliseconds: number): number {
		const dateDay = new Date(day);
		const dateTime = new Date(milliseconds);
		return Date.UTC(
			dateDay.getUTCFullYear(),
			dateDay.getUTCMonth(),
			dateDay.getUTCDate(),
			dateTime.getUTCHours(),
			dateTime.getUTCMinutes()
		);
	}

	/**
	 * sets the shift
	 * @param dateSelector
	 */
	setShift(dateSelector: DateSelector) {
		this.shiftButtonControl.setValue(dateSelector.shiftOn);
		this.shiftOn = dateSelector.shiftOn;
		this.shiftStart = dateSelector.shiftStart ?? 8 * 60 * 60 * 1000; // 08:00
		this.shiftEnd = dateSelector.shiftEnd ?? 18 * 60 * 60 * 1000; // 18:00
		this.selectedShiftDays.setValue(
			dateSelector.shiftDays?.map((shiftDay) => {
				return WeekDay[shiftDay].toString();
			}) || []
		);
	}

	/*
    /!**
     * returns true when weekday is in selected shift days, otherwise returns false
     * @param idx index
     *!/
    isSelectedShift(idx: number): boolean {
      return this.selectedShiftDays.includes(WeekDay[idx]);
    }

    /!**
     * returns true when weekday is in selected shift days and left to the day is no selected shift day,
     * otherwise returns false
     * @param idx index
     *!/
    isShiftDayStart(idx: number): boolean {
      return this.selectedShiftDays.includes(WeekDay[idx]) && !this.selectedShiftDays.includes(WeekDay[idx - 1]);
    }

    /!**
     * returns true when weekday is in selected shift days and right to the day is no selected shift day,
     * otherwise returns false
     * @param idx index
     *!/
    isShiftDayEnd(idx: number): boolean {
      return this.selectedShiftDays.includes(WeekDay[idx]) && !this.selectedShiftDays.includes(WeekDay[idx + 1]);
    }*/

	isGranularitySelected(mode: GranularityMode) {
		return this.currentGranularity === mode;
	}
}
