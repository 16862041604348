export class Union {
	/**
	 * constructs a union
	 * @param unionId union id
	 * @param name union name
	 * @param companyName company name
	 * @param serials list of serials
	 * @param city union city
	 */
	constructor(
		public unionId: string,
		public name: string,
		public companyName: string,
		public serials: Array<string>,
		public city: string
	) {}

	static generateFromJson(data: any): Union {
		return new Union(
			data.union_id,
			data.union_name,
			data.company_name,
			data.serials as Array<string>,
			data.city
		);
	}
}
