import { Role } from '@agilox/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { HeaderMachine } from 'apps/analytics/src/app/general/shared/services/machine/header-machine';
import { HeaderUnion } from 'apps/analytics/src/app/general/shared/services/machine/header-union';
import { AppsettingsService } from '../../services/appsettings/appsettings.service';

/**
 * displays a machine in the agilox-selection
 */
@Component({
	selector: 'agilox-analytics-machine',
	templateUrl: './machine.component.html',
	styleUrls: ['./machine.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineComponent implements OnInit, OnChanges {
	@Input() machine: HeaderMachine;
	@Input() small: boolean = false;

	@Output() machineClicked: EventEmitter<HeaderMachine> = new EventEmitter<HeaderMachine>();

	isUnion: boolean = false;
	isDisabled: boolean = false;
	isRoleAllowed: boolean = false;

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
		private appsettingsService: AppsettingsService
	) {
		this.isRoleAllowed =
			this.appsettingsService.user.role === Role.superuser ||
			this.appsettingsService.user.role === Role.support ||
			this.appsettingsService.user.role === Role.service;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['machine']) {
			this.checkDisabledState();
		}
	}

	ngOnInit() {
		this.isUnion = this.machine instanceof HeaderUnion;
	}

	clickedOnMachine() {
		if (!this.isDisabled) {
			this.machineClicked.emit(this.machine);
		}
		this.checkDisabledState();
	}

	checkDisabledState() {
		this.isDisabled = this.isRoleAllowed
			? false
			: this.machine.subscriptionExpired && !this.machine.selected;
		this.changeDetectorRef.markForCheck();
	}
}
