import { createFeature, createReducer, on } from '@ngrx/store';
import { setMultipleVehicles, setSingleVehicle } from './vehicle-filter.actions';

export interface VehiclesFilter {
	selectedVehicles: string[];
	machineToShow: string;
}

const initialState: VehiclesFilter = {
	selectedVehicles: [],
	machineToShow: undefined,
};

const reducer = createReducer(
	initialState,
	on(setMultipleVehicles, (state, action) => ({ ...state, selectedVehicles: action.value })),
	on(setSingleVehicle, (state, action) => ({ ...state, machineToShow: action.value }))
);

export const vehicleFilterFeature = createFeature({ name: 'globalVehicleFilter', reducer });
