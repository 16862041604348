<div
	class="machine"
	(click)="clickedOnMachine()"
	[ngClass]="{
		'machine--selected': machine.selected,
		'machine--small': small,
		'is-disabled': isDisabled,
	}"
	[attr.data-cy]="'machine-selector-' + machine.serial"
	#body
>
	<div class="machine__machineName">
		{{ machine.name }}
		<div class="machine__remoteReachable" *ngIf="!isUnion && machine.remoteReachable"></div>
		<ui-icon
			class="machine__tooltip"
			*ngIf="machine.subscriptionExpired"
			[uiTooltip]="'general.no_machine_subscription' | translate"
			icon="failures"
			type="problem"
			size="s"
			data-cy="machine-selector-subscription-expired-icon"
		></ui-icon>
	</div>
	<div class="machine__machineInfo">
		<span *ngIf="machine.serial">
			{{ isUnion && machine.city ? (machine.city | empty) : (machine.shortSerial | empty) }}
		</span>
		<span *ngIf="machine.serial && machine.company"> | </span>
		<span *ngIf="machine.company">{{ machine.company }}</span>
	</div>
	<div class="machine__machineInfo">
		<span *ngIf="machine.unionId">{{ machine.shortUnionId }}</span>
		<span *ngIf="machine.union && machine.unionId"> | </span>
		<span *ngIf="machine.union"
			>{{ machine.union
			}}{{ machine.collective?.length ? ' | ' + machine.collective?.join(', ') : '' }}</span
		>
	</div>
</div>
