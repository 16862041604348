import { inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { HttpParamsBuilder, Meta, Response } from '@agilox/common';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '@my/env/environment';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '@agilox/common';

@Injectable()
export class VehiclesSelectService {
	private http: HttpClient = inject(HttpClient);

	fetchVehicles(search: string, page: Meta, serials?: string[]): Observable<Response<Vehicle>> {
		let params = HttpParamsBuilder.buildParameters(page, undefined, search);
		if (serials?.length) {
			params = params.append('prioritize[serial]', serials.join(','));
		}
		return this.http
			.get<Response<Vehicle>>(environment.server + '/v2/machineMgmt/vehicles', {
				params,
			})
			.pipe(
				catchError(() => {
					return of({
						data: [],
						meta: {
							number: 0,
							size: 0,
							total: 0,
						},
						status: true,
					});
				})
			);
	}
}
