import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { PartialObserver, Subscription } from 'rxjs';
import { HeaderMenu } from 'apps/analytics/src/app/general/shared-desktop/selector/header-menu.enum';
import { SelectorService } from 'apps/analytics/src/app/general/shared-desktop/selector/selector.service';
import { AppsettingsService } from '../../services/appsettings/appsettings.service';

@Component({
	selector: 'agilox-analytics-spinner',
	templateUrl: './analytics-spinner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsSpinnerComponent implements OnInit, OnDestroy {
	/** data that is loaded */
	@Input() data: Array<any> = null;

	/** if link should be hidden */
	@Input() noLink: boolean | boolean = false;

	/**
	 *if spinner should be in light colors
	 */
	@Input() light: boolean | boolean = false;

	/** show spinner only when the data is actually loaded */
	@Input() isLoading: boolean | boolean = true;

	/** show small spinner */
	@Input() small: boolean | boolean = false;

	private machineSelectedSubscription: Subscription;
	private observer: PartialObserver<string[]> = {
		next: () => {
			this.isMachineSelected = this.appsettings.webAppSettings?.selectedMachines.length > 0;
			this.changeDetector.detectChanges();
		},
	};

	/** checks if there is a machine selected */
	isMachineSelected = false;

	constructor(
		private selectorService: SelectorService,
		private appsettings: AppsettingsService,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.isMachineSelected = this.appsettings.webAppSettings?.selectedMachines.length > 0;
		this.machineSelectedSubscription = this.appsettings.registerOnMachineSelected(this.observer);
	}

	ngOnDestroy() {
		this.machineSelectedSubscription?.unsubscribe();
	}

	/**
	 * opens the date selector
	 * @param event event
	 */
	openDateSelector(event: MouseEvent) {
		event.stopImmediatePropagation();
		this.selectorService.openSelector({ menu: HeaderMenu.calendar, event });
	}

	/**
	 * opens the machine selector
	 * @param event event
	 */
	openMachineSelector(event: MouseEvent) {
		event.stopImmediatePropagation();
		this.selectorService.openSelector({ menu: HeaderMenu.machines, event });
	}
}
