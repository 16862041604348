import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { DateSelector } from 'apps/analytics/src/app/general/shared/services/appsettings/date-selector.interface';
import { Granularity } from 'apps/analytics/src/app/general/shared/services/appsettings/granularity.enum';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Entry } from 'apps/analytics/src/app/general/shared/services/chart-data/entry';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { MachineService } from 'apps/analytics/src/app/general/shared/services/machine/machine.service';
import { PerformanceColor } from '@agilox/common';
import { ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { PartialObserver, Subscription } from 'rxjs';
import { PerformancePercentageName } from '../../../enums/performance-percentage-name.enum';
import { SerialDataSetsHolder } from './serial-datasets-holder.interface';

/**
 * displays the activity (busy, idle, onhold, ...) as bar-chart
 */
@Component({
	selector: 'agilox-analytics-bar-charts',
	templateUrl: './bar-charts.component.html',
	styleUrls: ['./bar-charts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartsComponent implements OnInit, OnDestroy, OnChanges {
	/**
	 * for the loading indicator
	 */
	notLoaded: boolean;

	/**
	 * holds the values which shouldn't get displayed
	 */
	@Input() doNotShow: Array<string>;

	/**
	 * the canvas for the chart
	 */
	@ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	private appsettingsSubscription: Array<Subscription>;
	private observer: PartialObserver<DateSelector | Array<string> | string> = {
		next: () => {
			this.fetchingData();
		},
	};

	private requestSubscription: Subscription;

	/**
	 * config for the bar-chart
	 */
	unit = '%';

	@Input() chartOptions: ChartOptions = {
		layout: {
			padding: {
				left: 25,
				right: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				position: 'left',
				display: true,
				ticks: {
					display: true,
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false, // hides the grid on the chart area
					lineWidth: 2,
					drawTicks: true,
					color: '#B7C9D3',
				},
			},
			y: {
				stacked: true,
				position: 'left',
				display: true,
				max: 100,
				min: 0,
				ticks: {
					display: true,
					maxTicksLimit: 5,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + (this.unit ?? ''),
				},
				grid: {
					drawOnChartArea: false, // hides the grid on the chart area
					lineWidth: 2,
					drawTicks: true,
					color: '#B7C9D3',
				},
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				intersect: false,
				bodySpacing: 2,
				callbacks: {
					label: (tooltipItem: TooltipItem<any>) => {
						const value = (tooltipItem.dataset.data[tooltipItem.dataIndex] * 100) / 100;
						const result = value - Math.floor(value) !== 0 ? value.toFixed(2) : value;
						return tooltipItem.dataset.label + ': ' + result + (this.unit ?? '');
					},
				},
				itemSort: (a, b) => {
					return b.datasetIndex - a.datasetIndex;
				},
			},
			legend: {
				display: false,
			},
		},
	};

	/**
	 * sets additional custom chart options
	 */
	@Input() set customOptions(value: { keys: Array<string>; property: string; value: any }) {
		if (value) {
			let options = this.chartOptions;
			value.keys.forEach((key, index) => {
				options = options[key];
				if (index === value.keys.length - 1) {
					options[value.property] = value.value;
				}
			});
		}
	}

	/**
	 * holds the chart-data
	 * gets initialized everytime a reload happens
	 */
	chartDataOriginalHolder: Array<SerialDataSetsHolder>;

	/**
	 * holds the chart-data which gets displayed
	 * gets changed everytime a reload happens or doNotShow-input changes
	 */
	chartDataToShowHolder: Array<SerialDataSetsHolder>;

	/**
	 * holds the chart-lables (the dates)
	 */
	chartLabelsHolder: Array<Array<any>>;

	/**
	 * holds the name of all machines
	 */
	machinesName: Array<string>;

	/** indicates if the server could load data */
	noData = false;

	/**
	 * fetches the data and registers observers
	 * @param chartService chartService
	 * @param appsettings appsettings
	 * @param translate translate
	 */
	constructor(
		private chartService: ChartDataService,
		private appsettings: AppsettingsService,
		private translate: TranslateService,
		private machineService: MachineService,
		private changeDetection: ChangeDetectorRef,
		private datePipe: DatePipe
	) {
		this.appsettingsSubscription = this.appsettings.registerOnWebappSettingChanged(this.observer);
	}

	/**
	 * fetches the data
	 */
	ngOnInit() {
		this.fetchingData();
	}

	/**
	 * unsubscribes every subscription
	 */
	ngOnDestroy() {
		this.requestSubscription?.unsubscribe();
		this.appsettingsSubscription.forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * when the selected input changes, it should react on it
	 */
	ngOnChanges() {
		if (this.chartDataOriginalHolder) {
			this.createChartDataToShow();
		}
	}

	private fetchingData() {
		this.notLoaded = true;
		this.noData = false;
		this.chartDataToShowHolder = null;
		this.changeDetection.detectChanges();
		this.requestSubscription?.unsubscribe();

		this.requestSubscription = this.chartService.getData(this.parseFetchedData.bind(this), false);
	}

	private safe(data: number): number {
		return data === undefined ? 0 : data;
	}

	getInitChartData(): Array<ChartDataset> {
		return Object.keys(PerformanceColor)
			.reverse()
			.map((key) => {
				return {
					label: this.translate.instant('performance.' + key),
					data: [],
					backgroundColor: PerformanceColor[key],
					borderColor: PerformanceColor[key],
				};
			});
	}

	/**
	 * transforms the fetched data to the data to show
	 */
	createChartDataToShow() {
		// no values selected
		if (!this.doNotShow || this.doNotShow.length === 0) {
			this.chartDataToShowHolder = this.chartDataOriginalHolder;
		} else {
			this.chartDataToShowHolder = new Array<SerialDataSetsHolder>();
			// we cant just simple asign the original data to the to-show-data because it would
			// just reference to the same object
			this.chartDataOriginalHolder.forEach((chartDataHolder) => {
				// create a new datasets object and remove the not needed datasets
				let newChartDataToShow = this.getInitChartData().filter(
					(chartData) => !this.doNotShow.find((str) => chartData.label === str)
				);

				// we need to reverse the dataset, because we do the same to original-data
				newChartDataToShow = newChartDataToShow.reverse();
				chartDataHolder.datasets
					// remove the not needed datasets
					.filter((chartData) => !this.doNotShow.find((str) => chartData.label === str))
					// add the data to the newly created dataset
					.forEach((dataset, idx) => {
						newChartDataToShow[idx].data = JSON.parse(JSON.stringify(dataset.data));
					});

				// if theres a dataset left, recalculate the left values
				// newvalue = current value * 100 / sum of all values
				if (newChartDataToShow.length > 0) {
					for (let i = 0; i < newChartDataToShow[0].data.length; i++) {
						const sum = +newChartDataToShow
							.map((dataset) => dataset.data[i])
							.reduce((total, current) => +total + +current, 0);

						newChartDataToShow.forEach(
							(dataset) => (dataset.data[i] = +((+dataset.data[i] * 100) / sum).toFixed(2))
						);
					}
				}

				this.chartDataToShowHolder.push({
					serial: chartDataHolder.serial,
					datasets: newChartDataToShow,
				});
			});

			this.setYAxis();
		}
	}

	/**
	 * fetches the name for all machines
	 */
	getMachineNames() {
		this.machinesName = new Array<string>(...this.chartDataToShowHolder.map((data) => data.serial));

		this.machineService.getNameOfMachines(
			{
				next: (names) => {
					this.machinesName = names;
					this.changeDetection.markForCheck();
				},
			},
			this.machinesName
		);
	}

	/**
	 * parses the fetched data
	 * @param data data
	 */
	parseFetchedData(data: Array<Machine>) {
		this.notLoaded = true;
		this.chartDataOriginalHolder = new Array<SerialDataSetsHolder>();
		this.chartLabelsHolder = new Array<Array<any>>();
		data.forEach((machine) => {
			const newChartData = this.getInitChartData();
			const newChartLabel = new Array<any>();

			machine.entries.forEach((entry: Entry) => {
				newChartData.forEach((bar) => {
					const performanceKey = Object.keys(PerformancePercentageName).find(
						(key) =>
							this.translate.instant('performance.' + PerformancePercentageName[key]) === bar.label
					);
					bar.data.push(this.safe(entry[performanceKey]));
				});
				newChartLabel.push(this.createLabel(entry.start));
			});

			this.chartDataOriginalHolder.push({
				serial: machine.serial,
				datasets: newChartData.reverse(),
			});
			this.chartLabelsHolder.push(newChartLabel);
		});

		if (
			this.chartDataOriginalHolder
				.map((data) => data.datasets.map((data) => data.data))
				.every((entry) => !entry)
		) {
			this.noData = true;
		} else {
			this.createChartDataToShow();
			this.getMachineNames();
		}

		this.setYAxis();
		this.notLoaded = false;
		this.changeDetection.detectChanges();
	}
	/**
	 * creates the labels
	 */
	private createLabel(milliseconds: number) {
		const labels = [];
		let dateFormat = 'HH:mm';
		let label = '';
		switch (this.appsettings.dateSelector.granularity) {
			case Granularity.mm:
				dateFormat = 'HH:mm';
				this.setXTicksRotation(45);
				break;
			case Granularity.hh:
				dateFormat = 'dd.MM.yy HH:mm';
				this.setXTicksRotation(45);
				break;
			case Granularity.dd:
				dateFormat = 'dd.MM.yy';
				this.setXTicksRotation(0);
				break;
			case Granularity.ww:
				dateFormat = 'ww/yyyy';
				this.setXTicksRotation(0);
				label = 'KW';
				break;
			case Granularity.MM:
				dateFormat = 'MM.yyyy';
				this.setXTicksRotation(0);
				break;
		}
		const date = this.datePipe.transform(milliseconds, dateFormat, 'UTC');
		labels.push(label + date);
		return labels;
	}

	/**
	 * sets the x ticks (labels) rotation
	 * @param rotation rotation in degree
	 */
	setXTicksRotation(rotation: number) {
		this.chartOptions.scales['x'].ticks['minRotation'] = rotation;
		this.chartOptions.scales['x'].ticks['maxRotation'] = rotation;
	}

	private setYAxis() {
		if (this.unit === '%' && this.chartOptions) {
			this.chartOptions.scales['y'].ticks['stepSize'] = 10;
			this.chartOptions.scales['y'].ticks.maxTicksLimit = 12;
		}
	}
}
