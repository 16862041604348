import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { FileService } from 'apps/analytics/src/app/general/shared/services/file/file.service';
import { Entry as TimelineEntry } from 'apps/analytics/src/app/general/widgets/components-for-widget-construction/timeline-chart/entry';
import { Timestamp } from 'apps/analytics/src/app/general/widgets/components-for-widget-construction/timeline-chart/timestamp';
import { FailuresEntry } from 'apps/analytics/src/app/menupoints/failures/failures-history/failures-entry';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BatteryUsage } from './battery-usage';
import { Entry } from './entry';
import { Machine } from './machine';
import { Performance } from './performance';
import { QuitData } from './quitData';
import { ReducedMachine } from './reduced-machine';
import { Summary } from './summary';
import { WeekSummary } from './week-summary';
import { WeightData } from './weightData';
import { environment } from '@analytics/env/environment';
import { GoogleAnalyticsService, MachineModel } from '@agilox/common';
import * as htmlToImage from 'html-to-image';
import { EntryConfig } from '../../../widgets/components-for-widget-construction/timeline-chart/entry-config';
import { WidgetColors } from '../../../enums/widget-colors.enum';

/**
 * provides the endpoints for the different charts. If you want
 * to use a simple-line-chart and you need to create a new function
 * for it, then the method needs 2 parameter:
 *  * PartialObserver
 */
@Injectable({
	providedIn: 'root',
})
export class ChartDataService {
	/**
	 * constructs the service and adds listeners on appsetting-service
	 * @param http http
	 * @param appsettingsService appsettingsService
	 * @param file
	 * @param googleAnalyticsService
	 * @param translateService
	 */
	constructor(
		private http: HttpClient,
		private appsettingsService: AppsettingsService,
		private file: FileService,
		private googleAnalyticsService: GoogleAnalyticsService
	) {}

	/**
	 * returns the data for the charts
	 * @param observer gets notified
	 */
	getData(observer: (data: Array<Machine>) => void, isRenderedInShowAll = false): Subscription {
		if (this.appsettingsService.generateReqObject().serial.length !== 0) {
			return this.http
				.post(
					environment.server + '/v2/Stats/report',
					this.appsettingsService.generateReqObject(isRenderedInShowAll)
				)
				.pipe(
					map((data: any) => {
						if (data.data) {
							let res: Array<Machine> = new Array<Machine>();
							res = Object.keys(data.data)?.map((key) =>
								Machine.generateFromJson(key, data.data[key].type, data.data[key].entries)
							);

							res?.forEach((machine) =>
								machine.entries.forEach((entry) => {
									if (entry instanceof Entry) {
										// we get the speed data in mm/s
										(entry as Entry).speedTarget = this.roundNum(
											(entry as Entry).speedTarget / 1000
										);
										(entry as Entry).speed = this.roundNum((entry as Entry).speed / 1000);

										// we get the distance data in mm
										(entry as Entry).distanceAbs = this.roundNum(
											(entry as Entry).distanceAbs / (1000 * 1000)
										);
										(entry as Entry).distance = this.roundNum(
											(entry as Entry).distance / (1000 * 1000)
										);
										(entry as Entry).distanceOccupiedAbs = this.roundNum(
											(entry as Entry).distanceOccupiedAbs / (1000 * 1000)
										);
										(entry as Entry).distanceOccupied = this.roundNum(
											(entry as Entry).distanceOccupied / (1000 * 1000)
										);

										(entry as Entry).weightAbs = this.roundNum((entry as Entry).weightAbs / 1000);
										(entry as Entry).weight = this.roundNum((entry as Entry).weight / 1000);
										(entry as Entry).battCur = this.roundNum((entry as Entry).battCur / 1000);
									}
								})
							);
							return res;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the failures from the server and returns the subscription for manual unsubscribing
	 * @param observer gets notified
	 */
	getFailuresHistory(observer: (data: Array<FailuresEntry>) => void): Subscription {
		const reqObj = this.appsettingsService.generateReqObject();
		return this.http
			.post(environment.server + '/v2/Stats/failureCount', reqObj)
			.pipe(
				map((data: any) => {
					if (data.data) {
						if (data.data.length !== 0) {
							return data.data[Object.keys(data.data)[0]]?.map((element) =>
								FailuresEntry.generateFromJson(element)
							);
						} else {
							return [];
						}
					}
					return data;
				})
			)
			.subscribe(observer);
	}

	/**
	 * returns the chart data just for the specific machine, is needed for the
	 * replay feature, always fetches the data for a whole day and ignores the given daytime
	 * @param observer gets notified
	 * @param serial serial of the wanted machine
	 */
	getDataForMachine(
		observer: (data: Array<Machine>) => void,
		serials: Array<string>
	): Subscription {
		const reqObject = this.appsettingsService.generateReqObject();
		reqObject.serial = serials;
		reqObject.res = 'hh';
		const startDate = new Date(reqObject.from * 1000);
		startDate.setUTCHours(0, 0, 0, 0);
		reqObject.from = startDate.getTime() / 1000;

		const endDate = new Date(reqObject.to * 1000);
		endDate.setUTCHours(23, 59, 59, 0);
		reqObject.to = endDate.getTime() / 1000;

		return this.http
			.post(environment.server + '/v2/Stats/report', reqObject)
			.pipe(
				map((data: any) => {
					if (data.data) {
						return Object.keys(data.data)?.map((key) =>
							Machine.generateFromJson(key, data.data[key].type, data.data[key].entries)
						);
					}
					return data;
				})
			)
			.subscribe(observer);
	}

	/**
	 * reduces the fetched data to the wanted attribute
	 * @param data data to reduce
	 * @param wanted the wanted attribtue
	 * @returns Array of ReducedMachines, but the data-array only has one entry
	 */
	reduceData(data: Array<Machine>, wanted: string[]): Array<ReducedMachine> {
		return data?.map((item) => {
			return {
				serial: item.serial,
				data: item.entries?.map((entry) => {
					let data = [];
					wanted.forEach((wantedAttribute) => {
						data.push(new TimelineEntry(wantedAttribute, entry[wantedAttribute]));
					});
					return new Timestamp(entry.start, data);
				}),
			};
		});
	}

	/**
	 * returns the data for the charts
	 * @param observer gets notfied
	 */
	getPerformanceData(
		observer: (data: Array<Performance>) => void,
		renderedInShowAll = false
	): Subscription {
		if (this.appsettingsService.generateReqObject().serial.length !== 0) {
			return this.http
				.post(
					environment.server + '/v2/Stats/dayPerformance',
					this.appsettingsService.generateReqObject(renderedInShowAll)
				)
				.pipe(
					map((data: any) => {
						if (data.data) {
							return Object.keys(data.data)?.map((key) =>
								Performance.generateFromJson(key, data.data[key])
							);
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * returns the data for the battery usage chart
	 * @param observer gets notfied
	 */
	getBatteryUsage(observer: (data: any) => void): Subscription {
		if (this.appsettingsService.generateReqObject().serial.length !== 0) {
			return this.http
				.post(
					environment.server + '/v2/Stats/batterySummary',
					this.appsettingsService.generateReqObject()
				)
				.pipe(
					map((data: any) => {
						if (data.data) {
							return Object.keys(data.data)?.map((key) =>
								BatteryUsage.generateFromJson(key, data.data[key])
							)?.[0];
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * returns the data for the transport-circle diagram
	 * @param observer gets notified
	 */
	getProductivitySummaryData(
		observer: (data: Array<Summary>) => void,
		renderedInShowAll = false
	): Subscription {
		if (this.appsettingsService.generateReqObject().serial.length !== 0) {
			return this.http
				.post(
					environment.server + '/v2/Stats/machineSummary',
					this.appsettingsService.generateReqObject(renderedInShowAll)
				)
				.pipe(
					map((data: any) => {
						if (data.data) {
							return Object.keys(data.data)?.map((key) =>
								Summary.generateFromJson(key, data.data[key])
							);
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * returns the data for the widget
	 * @param observer gets notified
	 */
	getWeightData(observer: (data: Array<Summary>) => void, renderedInShowAll = false): Subscription {
		if (this.appsettingsService.generateReqObject().serial.length !== 0) {
			return this.http
				.post(
					environment.server + '/v2/Stats/weightAnalysis',
					this.appsettingsService.generateReqObject(renderedInShowAll)
				)
				.pipe(
					map((data: any) => {
						if (data.data) {
							return Object.keys(data.data)?.map((key) =>
								WeightData.generateFromJson(data.data[key])
							);
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * returns the data for the widget
	 * @param observer gets notified
	 */
	getQuitData(observer: (data: Array<Summary>) => void, renderedInShowAll = false): Subscription {
		if (this.appsettingsService.generateReqObject().serial.length !== 0) {
			return this.http
				.post(
					environment.server + '/v2/Stats/quitAnalysis',
					this.appsettingsService.generateReqObject(renderedInShowAll)
				)
				.pipe(
					map((data: any) => {
						if (data.status) {
							return Object.keys(data.data)?.map((key) =>
								QuitData.generateFromJson(data.data[key])
							);
						}
						return new Array<Summary>();
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * returns the data for the weeksummary diagram
	 * @param observer gets notified
	 */
	getWeekSummaryData(
		observer: (data: Array<WeekSummary>) => void,
		renderedInShowAll = false
	): Subscription {
		return this.http
			.post(
				environment.server + '/v2/Stats/weekOrderTrend',
				this.appsettingsService.generateReqObject(renderedInShowAll)
			)
			.pipe(
				map((data: any) => {
					if (data.status) {
						return Object.keys(data.data)?.map((key) =>
							WeekSummary.generateFromJson(key, data.data[key])
						);
					}
					return new Array<WeekSummary>();
				})
			)
			.subscribe(observer);
	}

	transformBatteryData(
		data: Machine[],
		attributes: string[],
		attributeTranslations: string[]
	): [Timestamp[], EntryConfig[]] {
		let reduced: ReducedMachine[] = [];
		let configs: EntryConfig[] = [];
		const timestamps: Timestamp[] = [];
		const entries = [
			new EntryConfig(attributeTranslations[0], {
				borderColor: WidgetColors[0],
				backgroundColor: WidgetColors[0],
			}),
		];

		if (data[0]?.model === MachineModel.OPS) {
			reduced = this.reduceData(data, attributes);
			configs = [
				new EntryConfig(attributeTranslations[1], {
					borderColor: WidgetColors[1],
					backgroundColor: WidgetColors[1],
				}),
				entries[0],
			];
		} else {
			reduced = this.reduceData(data, [attributes[0]]);
			configs = entries;
		}
		reduced.forEach((item) => {
			item.data.forEach((datapoint: Timestamp) => {
				let foundTimestamp = timestamps.find((stamp) => datapoint.unix === stamp.unix);
				if (!foundTimestamp) {
					foundTimestamp = new Timestamp(datapoint.unix, []);
					timestamps.push(foundTimestamp);
				}
				datapoint.data.forEach((entry: TimelineEntry, index: number) => {
					foundTimestamp.data.push(
						new TimelineEntry(attributeTranslations[index], entry?.value ?? 0)
					);
				});
			});
		});

		return [timestamps, configs];
	}

	/**
	 * export stats
	 */
	exportStats() {
		const reqObject = this.appsettingsService.generateReqObject();
		reqObject.export = 'csv';
		this.http.post<any>(environment.server + '/v2/Stats/report', reqObject).subscribe({
			next: (data) => this.file.downloadFile(data.filename, data.data),
		});
		this.googleAnalyticsService.eventEmitter('click: export-report-csv', 'export-report-csv');
	}

	exportWidget(id: string) {
		this.sizeTableChartForExport(id);

		// filter out the select and icon elements
		const filter = (node: HTMLElement) => {
			const excludedClasses: string[] = [
				'widget-header__select',
				'widget-header__icons',
				'widget-header__singleMachine',
				'table-chart__more',
				'widget-header__iconsMobile',
			];
			return !excludedClasses.some((classname: string) => node.classList?.contains(classname));
		};

		htmlToImage
			.toPng(document.querySelector('#' + id) as HTMLElement, {
				filter: filter,
				backgroundColor: 'white',
			})
			.then((dataUrl) => {
				this.file.downloadBase64ToPng(id + '.png', dataUrl);
				this.undoSizeTableChartForExport(id);
				this.googleAnalyticsService.eventEmitter('click: export-' + id, 'export-' + id);
			});
	}

	/**
	 * When the chart (table-chart) is scrollable we need to adjust the size of the widget for the export
	 * as only the visible part of the widget is exported
	 */
	private sizeTableChartForExport(id: string) {
		const element = document.querySelector('#' + id) as HTMLElement;
		const tableChart: HTMLElement = element.querySelector('.table-chart');
		if (tableChart === null) {
			return;
		}
		// get the gridster item
		const gridsterItem = this.getAncestorByTagName(element, 'gridster-item');
		if (gridsterItem) {
			gridsterItem.classList.add('is-export');
		}
		tableChart.classList.add('is-export');
	}

	private undoSizeTableChartForExport(id: string) {
		const element = document.querySelector('#' + id) as HTMLElement;
		const tableChart: HTMLElement = element.querySelector('.table-chart');
		if (tableChart === null) {
			return;
		}
		// get the gridster item
		const gridsterItem = this.getAncestorByTagName(element, 'gridster-item');
		if (gridsterItem) {
			gridsterItem.classList.remove('is-export');
		}

		tableChart.classList.remove('is-export');
	}

	private getAncestorByTagName(element: HTMLElement, tagName: string): HTMLElement {
		let parent = element.parentElement;
		while (parent) {
			if (parent.tagName.toLowerCase() === tagName) {
				return parent;
			}
			parent = parent.parentElement;
		}
		return null;
	}

	private roundNum(num: number): number {
		return Math.round(num * 100) / 100;
	}
}
