<div class="machine-selector">
	<div class="machine-selector__search search">
		<ui-input
			type="search"
			inputId="search-agilox-union"
			[placeholder]="'general.search_agilox_union' | translate"
			[disabled]="!machines?.length"
			[(ngModel)]="searchText"
			class="w-75"
			#searchInputField
		>
			<label label for="search-agilox-union">{{ 'general.search_agilox_union' | translate }}</label>
		</ui-input>
	</div>

	<div class="machine-selector__container">
		<div class="machine-selector__title">
			{{ 'header.machines' | translate }}
			<span class="counter counter--m" *ngIf="machinesToDisplay?.length > 1">{{
				selectedMachines.length
			}}</span>
		</div>
		<div class="machine-selector__machines" #machinesContainer>
			<div class="notify__message" *ngIf="machinesToDisplay?.length === 0">
				{{ 'general.machines.no_machines_found' | translate }}
			</div>
			<agilox-analytics-spinner
				[small]="true"
				class="machine-selector__spinner"
				*ngIf="!machinesToDisplay"
			>
			</agilox-analytics-spinner>
			<agilox-analytics-machine
				#machines
				*ngFor="let machine of machinesToDisplay; trackBy: trackByFunction"
				[machine]="machine"
				(machineClicked)="toggleMachine($event, machine)"
				(contextmenu)="handleMachineHiddenFeature($event, machine)"
			>
			</agilox-analytics-machine>
		</div>

		<div class="machine-selector__title">
			{{ 'header.unions' | translate }}
			<span class="counter counter--m" *ngIf="unionsToDisplay?.length > 1">{{
				selectedUnions.length
			}}</span>
		</div>
		<div class="machine-selector__unions" #unionsContainer>
			<div class="notify__message" *ngIf="unionsToDisplay?.length === 0">
				{{ 'general.machines.no_unions_found' | translate }}
			</div>
			<agilox-analytics-spinner
				[small]="true"
				class="machine-selector__spinner"
				*ngIf="!unionsToDisplay"
			>
			</agilox-analytics-spinner>
			<agilox-analytics-machine
				#unions
				*ngFor="let union of unionsToDisplay; trackBy: trackByFunction"
				[machine]="union"
				(contextmenu)="copyUnionName($event, union)"
				(click)="toggleUnion(union)"
			>
			</agilox-analytics-machine>
		</div>
	</div>
	<div class="machine-selector__footer">
		<div class="machine-selector__selectLinks">
			<ui-button
				[uiTooltip]="
					(machinesToDisplay?.length > 20 || machinesToDisplay?.length === 0
						? 'general.select_all_machines_info'
						: ''
					) | translate
				"
				[text]="'general.select_all' | translate"
				(buttonClicked)="toggleAllMachines(true)"
				type="link"
				[disabled]="
					machinesToDisplay?.length > 20 ||
					machinesToDisplay?.length === 0 ||
					selectedMachines?.length === machinesToDisplay?.length
				"
			>
			</ui-button>

			<div class="divider">|</div>
			<ui-button
				[text]="'general.deselect_all' | translate"
				(buttonClicked)="toggleAllMachines(false)"
				type="link"
				[disabled]="selectedMachines.length === 0 || machinesToDisplay?.length === 0"
			></ui-button>
		</div>

		<div class="machine-selector__buttons">
			<p class="machine-selector__error" *ngIf="tooManyMachines">
				{{ 'general.too_many_machines' | translate }}
			</p>
			<ui-button
				[text]="'general.cancel' | translate"
				(buttonClicked)="invokeCancel()"
				type="link"
			></ui-button>
			<ui-button
				[text]="'general.save' | translate"
				(buttonClicked)="!tooManyMachines ? invokeSave() : false"
				[disabled]="tooManyMachines"
				type="primary"
				data-cy="machine-selector-save"
			></ui-button>
		</div>
	</div>
</div>
