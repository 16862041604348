import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'apps/analytics/src/environments/environment';
import { Subscription, map } from 'rxjs';
import { AppsettingsService } from '../appsettings/appsettings.service';
import { HeaderMachine } from './header-machine';
import { Union } from './union';

@Injectable({ providedIn: 'root' })
export class MachineSelectorService {
	constructor(
		private httpClient: HttpClient,
		private appsettingsService: AppsettingsService
	) {}

	/**
	 * fetches all unions
	 * @param observer gets notified
	 */
	getAllUnions(observer: (data: Array<Union>) => void): Subscription {
		const reqObject = {
			serial: [],
			from: this.appsettingsService.dateSelector.startDate / 1000,
			to: this.appsettingsService.dateSelector.endDate / 1000,
		};

		return this.httpClient
			.post(environment.server + '/v2/machineMgmt/unions', reqObject)
			.pipe(
				map((data: any) => {
					let unions = new Array<Union>();
					if (data.status) {
						unions = (data.unions as Array<any>).map((union) => Union.generateFromJson(union));
					}
					return unions;
				})
			)
			.subscribe(observer);
	}

	/**
	 * returns all machines
	 * @param observer gets notified
	 */
	getAllMachines(observer: (data: Array<HeaderMachine>) => void): Subscription {
		const reqObj = {
			active_only: true,
		};

		return this.httpClient
			.post(environment.server + '/v2/MachineMgmt/list', reqObj)
			.pipe(
				map((data: any) => {
					let machines = new Array<HeaderMachine>();
					if (data.status) {
						machines = (data.machines as Array<any>).map((machine) =>
							HeaderMachine.generateFromJson(machine)
						);
					}
					return machines;
				})
			)
			.subscribe(observer);
	}
}
