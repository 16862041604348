import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Mode } from 'apps/analytics/src/app/general/shared/services/appsettings/mode.enum';
import { SaveService } from '../save/save.service';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
	selector: 'agilox-analytics-mode-selector',
	templateUrl: './mode-selector.component.html',
	styleUrls: ['./mode-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModeSelectorComponent extends SubscriptionHandler {
	/** holds all modes */
	modes = Object.keys(Mode);

	/** selected mode */
	selectedMode: Mode;

	constructor(
		private appsettings: AppsettingsService,
		saveService: SaveService,
		private changeDetector: ChangeDetectorRef
	) {
		super(saveService);
		this.selectedMode = this.appsettings.dateSelector.mode;
	}

	/**
	 * sets the selected mode
	 * @param mode selected mode
	 */
	selectMode(mode: string) {
		this.selectedMode = Mode[mode];
	}

	/**
	 * saves the mode in appsettings
	 */
	save() {
		if (this.appsettings.dateSelector.mode !== this.selectedMode) {
			this.appsettings.saveMode(this.selectedMode);
		}
	}

	cancel() {
		this.selectedMode = this.appsettings.dateSelector.mode;
		this.changeDetector.detectChanges();
	}
}
