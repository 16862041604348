import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableExporterDirective } from 'apps/analytics/src/app/general/material/mat-table-exporter/mat-table-exporter.directive';
import { StandardSizes } from '@agilox/ui-common';

@Component({
	selector: 'agilox-analytics-export-button',
	templateUrl: './export-button.component.html',
	styleUrls: ['./export-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportButtonComponent implements OnDestroy {
	/** if spinner in export button is shown */
	showSpinner: boolean;

	/** subscription for export table */
	private exportSubscription: Subscription;

	/** data */
	@Input() data: any;

	/** file name */
	@Input() fileName: string;

	private _exporter: MatTableExporterDirective;

	/** exporter */
	@Input() set exporter(value: MatTableExporterDirective) {
		this._exporter = value;

		// subscribes to export completed
		this._exporter?.exportCompleted.subscribe({
			next: () => {
				this.showSpinner = false;
				this.exportFinished.emit();
				this.changeDetectorRef.detectChanges();
			},
		});
	}

	get exporter(): MatTableExporterDirective {
		return this._exporter;
	}

	/** button disabled */
	@Input() disabled = false;

	@Output() exportFinished = new EventEmitter<void>();

	@Input() size: StandardSizes = 'l';

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	/**
	 * unsubscribes all subscriptions
	 */
	ngOnDestroy() {
		this.exportSubscription?.unsubscribe();
	}

	/**
	 * exports the mat table
	 * @param exporter table exporter
	 */
	exportTable(exporter: MatTableExporterDirective) {
		this.showSpinner = true;
		const fileName = this.fileName ? this.fileName : 'export';
		exporter.exportTable('csv', { delimiter: ';', fileName: fileName });
	}
}
