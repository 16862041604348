import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs';
import { AppsettingsService } from '../general/shared/services/appsettings/appsettings.service';
import { initAppSettings } from './app-settings.actions';
import { ApplicationSettings } from '../general/interfaces/application-settings.interface';
import { setDateFilter } from './date-filter/date-filter.actions';
import { setMultipleVehicles, setSingleVehicle } from './vehicles-filter/vehicle-filter.actions';

@Injectable()
export class AppSettingsEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	loadAppSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initAppSettings),
			mergeMap(() => {
				return this.appSettingsService
					.getUserAppSettings()
					.pipe(
						mergeMap((appSettings: ApplicationSettings) => [
							setDateFilter({ value: appSettings.dateSelector }),
							setMultipleVehicles({ value: appSettings.selectedMachines }),
							setSingleVehicle({ value: appSettings.machineToShow }),
						])
					);
			})
		)
	);
}
