import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import {
	saveMultipleVehicles,
	saveSingleVehicle,
	setMultipleVehicles,
} from './vehicle-filter.actions';
import { map } from 'rxjs/operators';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';

@Injectable()
export class VehicleFilterEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveSingleSelectVehicle$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveSingleVehicle),
				mergeMap((action: any) => {
					return this.appSettingsService.saveGlobalSingleSelectVehicles(action.value);
				})
			),
		{ dispatch: false }
	);

	saveMultipleSelectedVehicles$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveMultipleVehicles),
				mergeMap((action: { value: string[] }) =>
					this.appSettingsService
						.saveGlobalMultiSelectVehicles(action.value)
						.pipe(
							map((filter: ApplicationSettings) =>
								setMultipleVehicles({ value: filter.selectedMachines })
							)
						)
				)
			),
		{ dispatch: true }
	);
}
