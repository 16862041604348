<ui-notify
	class="subscription-expired-notify"
	data-cy="subscription-expired-notify"
	*ngIf="notifyVisible"
	[text]="
		'general.subscription_for_machine' +
			(subscriptionExpiredMachines.length > 1 ? 's' : '') +
			'_expired' | translate: { machines: expiredMachinesText }
	"
	type="warning"
></ui-notify>
