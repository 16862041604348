import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { getSubscriptionRemaining, Vehicle } from '@agilox/common';

@Component({
	selector: 'ui-vehicle-select-option',
	templateUrl: './vehicle-select-option.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSelectOptionComponent {
	@Input() option!: SelectOption<Vehicle>;
	@Input() selected: boolean = false;
	@Input() displayPills!: boolean;

	@Output() toggleItem: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();

	hasSubscriptionExpired(): boolean {
		return getSubscriptionRemaining(this.option.value) <= 0;
	}

	toggleItemHandler(option: SelectOption<Vehicle>): void {
		if (!option.isDisabled) {
			this.toggleItem.emit(option.value);
		}
	}
}
