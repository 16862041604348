<div class="timeselect">
	<form class="timeselect__timeContainer" [formGroup]="timeFormGroup">
		<div class="timeselect__timeChooser">
			<ui-input
				formControlName="hour"
				[hideClearIcon]="true"
				(focusout)="setTimeFormGroup(getTime())"
			>
			</ui-input>
		</div>
		<div class="timeselect__timeChooserDivider">:</div>
		<div class="timeselect__timeChooser">
			<ui-input
				formControlName="minute"
				[hideClearIcon]="true"
				(focusout)="setTimeFormGroup(getTime())"
			>
			</ui-input>
		</div>
	</form>
</div>
