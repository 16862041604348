import { Injectable } from '@angular/core';
import { GridsterConfig } from 'angular-gridster2';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { WidgetConfig } from 'apps/analytics/src/app/general/shared/services/appsettings/widget-config';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { BehaviorSubject, PartialObserver, Subject, Subscription } from 'rxjs';
import { WidgetConfigResolverService } from './widget-config-resolver.service';

@Injectable({
	providedIn: 'root',
})
export class WidgetService {
	private onDashboardChanged: Subject<void>;
	private allWidgetConfigs: Array<WidgetConfig>;

	constructor(
		private appsettings: AppsettingsService,
		private chartService: ChartDataService,
		private widgetConfigResolverService: WidgetConfigResolverService
	) {
		this.onDashboardChanged = new Subject<void>();
		this.allWidgetConfigs = new Array<WidgetConfig>(
			...this.getBatteryDefault(),
			...this.getDashboardDefault(),
			...this.getProductivityAnalysisDefault(),
			...this.getProductivityHistoryDefault(),
			...this.getProcessesDefault(),
			...this.getFailuresDefault()
		);
	}

	private helpTextSubject = new BehaviorSubject<string | null>(null);
	helpText$ = this.helpTextSubject.asObservable();
	/**
	 * saves the settings on the server
	 */
	saveSettingsOnServer() {
		this.appsettings.saveAppSettingsOnServer();
	}

	/**
	 * Checks if the given templateId is pinned on the dashboard
	 * @param templateId template which could be pinned
	 */
	isWidgetPinned(name: string): boolean {
		return !!this.appsettings.webAppSettings.dashboardWidgetConfig.find(
			(elem) => elem.name === name
		);
	}

	/**
	 * returns the default gridsteroptions
	 * https://tiberiuzuld.github.io/angular-gridster2/misc
	 */
	getDefaultGridsterOptions(): GridsterConfig {
		return {
			minCols: 2,
			maxCols: 2,
			margin: 24,
			mobileBreakpoint: 1024,
			outerMargin: true,
			defaultItemCols: 1,
			defaultItemRows: 1,
			minItemRows: 1,
			maxItemRows: 1,
			outerMarginTop: 12,
			outerMarginLeft: 6,
			outerMarginBottom: 12,
			compactType: 'compactLeft&Up',
			draggable: {
				enabled: false,
				dropOverItems: false,
			},
			gridType: 'verticalFixed',
			pushItems: false,
			resizable: {
				enabled: false,
			},
			itemChangeCallback: () => {
				this.saveSettingsOnServer();
			},
			fixedRowHeight: 350,
			keepFixedHeightInMobile: true,
		};
	}

	/**
	 * subscribe on every change the dashboards gets
	 * @param observer observer to observe
	 */
	subscribeOnDashboardChanged(observer: PartialObserver<void>): Subscription {
		return this.onDashboardChanged.subscribe(observer);
	}

	/**
	 * gets you the associated widgets
	 */
	getDashboard(): Array<WidgetConfig> {
		const validWidgets = this.widgetConfigResolverService.checkWidgets(
			this.appsettings.webAppSettings.dashboardWidgetConfig
		);
		if (validWidgets.length !== this.appsettings.webAppSettings.dashboardWidgetConfig.length) {
			this.appsettings.webAppSettings.dashboardWidgetConfig = validWidgets;
			this.saveSettingsOnServer();
		}

		this.checkForUpdate();

		return this.appsettings.webAppSettings.dashboardWidgetConfig;
	}

	/** checks if widget config has changed in the meantime and
	 *  update webAppSettings.dashboardWidgetConfig
	 */
	checkForUpdate() {
		let needsUpdate = false;

		this.appsettings.webAppSettings.dashboardWidgetConfig.map((widget) => {
			const widgetConfig = this.getWidgetConfigByName(widget.name);

			if (widget.gridsterItem.cols > 2 || widget.cols > 2) {
				widget.cols = widgetConfig.cols;
				widget.gridsterItem.cols = widgetConfig.gridsterItem.cols;
				needsUpdate = true;
			}

			if (widget.gridsterItem.x > 1 || widget.x > 1) {
				widget.x = 1;
				widget.gridsterItem.x = 1;
				needsUpdate = true;
			}

			if (widget.disableOnComb === undefined || widget.disableOnSum === undefined) {
				widget.disableOnComb = widgetConfig.disableOnComb;
				widget.disableOnSum = widgetConfig.disableOnSum;
				needsUpdate = true;
			}
		});

		if (needsUpdate) {
			this.saveSettingsOnServer();
		}
	}

	/**
	 * Toggles a widget to or off the dashboard
	 * @param widget widget to toggle
	 */
	toggleWidgetOnDashboard(name: string) {
		const dashboard = this.appsettings.webAppSettings.dashboardWidgetConfig;
		if (this.isWidgetPinned(name)) {
			for (let i = dashboard.length - 1; i > -1; i--) {
				if (dashboard[i].name === name) {
					dashboard.splice(i, 1);
				}
			}
		} else {
			dashboard.push(this.getWidgetConfigByName(name));
		}

		this.saveSettingsOnServer();

		// notifying all observers
		this.onDashboardChanged.next();
	}

	/**
	 * gets you the associated widgets
	 */
	getDashboardDefault(): Array<WidgetConfig> {
		return [
			new WidgetConfig(0, 0, 1, 'performance_summary', true, false, () =>
				this.chartService.exportWidget('performance_summary')
			),
			new WidgetConfig(1, 0, 1, 'productivity_summary', true, false, () =>
				this.chartService.exportWidget('productivity_summary')
			),
			new WidgetConfig(0, 1, 1, 'productivity_analysis', true, false, () =>
				this.chartService.exportWidget('productivity_analysis')
			),
			new WidgetConfig(1, 1, 1, 'failures_process', true, false, () =>
				this.chartService.exportWidget('failures_process')
			),
			new WidgetConfig(0, 2, 1, 'processes_canceled', true, false, () =>
				this.chartService.exportWidget('processes_canceled')
			),
			new WidgetConfig(1, 2, 1, 'processes_pickups', true, false, () =>
				this.chartService.exportWidget('processes_pickups')
			),
			new WidgetConfig(0, 3, 1, 'distance_pie', true, false, () =>
				this.chartService.exportWidget('distance_pie')
			),
			new WidgetConfig(1, 3, 1, 'weight_analysis', true, true, () =>
				this.chartService.exportWidget('weight_analysis')
			),
		];
	}

	/**
	 * gets you the associated widgets
	 */
	getBatteryDefault(): Array<WidgetConfig> {
		const res = new Array<WidgetConfig>();
		res.push(
			new WidgetConfig(0, 0, 1, 'battery_level', true, true, () =>
				this.chartService.exportWidget('battery_level')
			)
		);
		res.push(
			new WidgetConfig(1, 0, 1, 'cell_voltage', true, true, () =>
				this.chartService.exportWidget('cell_voltage')
			)
		);
		res.push(
			new WidgetConfig(0, 1, 1, 'battery_current', true, true, () =>
				this.chartService.exportWidget('battery_current')
			)
		);
		res.push(
			new WidgetConfig(1, 1, 1, 'battery_difference', true, true, () =>
				this.chartService.exportWidget('battery_difference')
			)
		);
		res.push(
			new WidgetConfig(0, 2, 1, 'battery_usage', true, true, () =>
				this.chartService.exportWidget('battery_difference')
			)
		);
		res.push(
			new WidgetConfig(1, 2, 1, 'battery_temperature', true, true, () =>
				this.chartService.exportWidget('battery_temperature')
			)
		);
		res.push(
			new WidgetConfig(0, 3, 1, 'charge_count', true, true, () =>
				this.chartService.exportWidget('charge_count')
			)
		);

		return res;
	}

	/**
	 * gets you the associated widgets
	 */
	getProductivityAnalysisDefault(): Array<WidgetConfig> {
		const res = new Array<WidgetConfig>();
		res.push(
			new WidgetConfig(0, 0, 1, 'performance_summary', true, false, () =>
				this.chartService.exportWidget('performance_summary')
			)
		);
		res.push(
			new WidgetConfig(1, 0, 1, 'productivity_summary', true, false, () =>
				this.chartService.exportWidget('productivity_summary')
			)
		);
		res.push(
			new WidgetConfig(0, 1, 1, 'productivity_analysis', true, false, () =>
				this.chartService.exportWidget('productivity_analysis')
			)
		);
		res.push(
			new WidgetConfig(1, 1, 1, 'distance_pie', true, false, () =>
				this.chartService.exportWidget('distance_pie')
			)
		);
		res.push(
			new WidgetConfig(0, 2, 1, 'weight_analysis', true, true, () =>
				this.chartService.exportWidget('weight_analysis')
			)
		);
		return res;
	}

	getProductivityHistoryDefault(): Array<WidgetConfig> {
		const res = new Array<WidgetConfig>();
		res.push(
			new WidgetConfig(0, 0, 2, 'productivity_history', true, false, () =>
				this.chartService.exportWidget('productivity_history')
			)
		);
		res.push(
			new WidgetConfig(0, 1, 2, 'pickup_count', false, false, () =>
				this.chartService.exportWidget('pickup_count')
			)
		);
		res.push(
			new WidgetConfig(0, 2, 1, 'weight_absolute', false, false, () =>
				this.chartService.exportWidget('weight_absolute')
			)
		);
		res.push(
			new WidgetConfig(1, 2, 1, 'widget_weight', false, false, () =>
				this.chartService.exportWidget('widget_weight')
			)
		);
		res.push(
			new WidgetConfig(0, 3, 1, 'distance_absolute', true, false, () =>
				this.chartService.exportWidget('distance_absolute')
			)
		);
		res.push(
			new WidgetConfig(1, 3, 1, 'distance', true, false, () =>
				this.chartService.exportWidget('distance')
			)
		);
		res.push(
			new WidgetConfig(0, 4, 1, 'lift_height_absolute', true, false, () =>
				this.chartService.exportWidget('lift_height_absolute')
			)
		);
		res.push(
			new WidgetConfig(1, 4, 1, 'lift_height', true, false, () =>
				this.chartService.exportWidget('lift_height')
			)
		);

		res.push(
			new WidgetConfig(0, 5, 1, 'current_speed', false, true, () =>
				this.chartService.exportWidget('current_speed')
			)
		);
		res.push(
			new WidgetConfig(1, 5, 1, 'park_count', false, false, () =>
				this.chartService.exportWidget('park_count')
			)
		);
		res.push(
			new WidgetConfig(0, 6, 1, 'rotation_absolute', true, false, () =>
				this.chartService.exportWidget('rotation_absolute')
			)
		);
		res.push(
			new WidgetConfig(1, 6, 1, 'rotation_driving_units', true, false, () =>
				this.chartService.exportWidget('rotation_driving_units')
			)
		);

		return res;
	}

	/**
	 * gets you the associated widgets for processes
	 */
	getProcessesDefault(): Array<WidgetConfig> {
		const res = new Array<WidgetConfig>();
		res.push(
			new WidgetConfig(0, 0, 1, 'processes_canceled', true, false, () =>
				this.chartService.exportWidget('processes_canceled')
			)
		);
		res.push(
			new WidgetConfig(1, 0, 1, 'processes_workflows', true, false, () =>
				this.chartService.exportWidget('processes_workflows')
			)
		);
		res.push(
			new WidgetConfig(0, 1, 1, 'processes_pickups', true, false, () =>
				this.chartService.exportWidget('processes_pickups')
			)
		);
		res.push(
			new WidgetConfig(1, 1, 1, 'processes_drops', true, false, () =>
				this.chartService.exportWidget('processes_drops')
			)
		);

		return res;
	}

	/**
	 * gets you the associated widgets
	 */
	getFailuresDefault(): Array<WidgetConfig> {
		const res = new Array<WidgetConfig>();
		res.push(
			new WidgetConfig(0, 0, 1, 'failures_types', true, false, () =>
				this.chartService.exportWidget('failures_types')
			)
		);
		res.push(
			new WidgetConfig(1, 0, 1, 'failures_history', true, false, () =>
				this.chartService.exportWidget('failures_history')
			)
		);
		res.push(
			new WidgetConfig(0, 1, 1, 'failures_process', true, false, () =>
				this.chartService.exportWidget('failures_process')
			)
		);
		res.push(
			new WidgetConfig(1, 1, 1, 'failures_technical', true, false, () =>
				this.chartService.exportWidget('failures_technical')
			)
		);
		res.push(
			new WidgetConfig(0, 2, 1, 'failures_quit', true, false, () =>
				this.chartService.exportWidget('failures_quit')
			)
		);

		return res;
	}

	/**
	 * returns the full widgetconfig
	 * @param title title of the widgetconfig
	 */
	getWidgetConfigByName(name: string): WidgetConfig {
		return this.allWidgetConfigs.find((config) => config.name === name);
	}

	setHelpText(data: string) {
		this.helpTextSubject.next(data);
	}

	clearHelpText() {
		this.helpTextSubject.next(null);
	}
}
