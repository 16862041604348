<div class="date-selector">
	<div class="date-selector__container">
		<div class="date-selector__left">
			<!-- Calendar Links -->
			<div class="date-selector__links">
				<div
					[uiTooltip]="mode.description"
					*ngFor="let mode of dateModes"
					class="date-selector__link link"
					directiveGoogleAnalytics
					eventAction="click: date-link"
					[eventLabel]="mode.name"
				>
					<div (click)="dateModeSelected(mode)" [attr.data-cy]="'date-selector-' + mode.name">
						{{ mode.translation | translate }}
					</div>
				</div>
			</div>

			<div class="date-selector__calendars">
				<!-- Left calendar -->
				<div class="date-selector__calendar">
					<span class="date-selector__label hide-mobile">{{
						'general.datepicker.time_picker.start_date' | translate
					}}</span>
					<agilox-analytics-calendar
						[show]="timestampToShowLeft"
						[startDay]="startDay"
						[endDay]="endDay"
						(startDayChanged)="setStartDayResetEndDay($event)"
						(endDayChanged)="setEndDay($event)"
						class="date-selector__cal"
					></agilox-analytics-calendar>

					<div
						class="date-selector__time"
						*ngIf="
							!shiftOn &&
							!granularityDaySelected &&
							!granularityWeekSelected &&
							!granularityMonthSelected
						"
					>
						<div class="date-selector__timeContainer">
							<div class="date-selector__label">{{ 'general.shift.start_time' | translate }}</div>
							<agilox-analytics-timeselect
								[(data)]="timeStartDay"
								[hourMax]="23"
								[(valid)]="startTimeValid"
								(validChange)="checkEndTimeValid()"
								(dataChange)="checkGranularity()"
							>
							</agilox-analytics-timeselect>
						</div>
					</div>
				</div>

				<!-- Right calendar -->
				<div class="date-selector__calendar date-selector__calendar--end">
					<span class="date-selector__label hide-mobile">{{
						'general.datepicker.time_picker.end_date' | translate
					}}</span>
					<agilox-analytics-calendar
						[show]="timestampToShowRight"
						[startDay]="startDay"
						[endDay]="endDay"
						(startDayChanged)="setStartDayResetEndDay($event)"
						(endDayChanged)="setEndDay($event)"
						class="date-selector__cal hide-mobile"
					></agilox-analytics-calendar>

					<div
						class="date-selector__time"
						*ngIf="
							!shiftOn &&
							!granularityDaySelected &&
							!granularityWeekSelected &&
							!granularityMonthSelected
						"
					>
						<div class="date-selector__timeContainer">
							<div class="date-selector__label">{{ 'general.shift.end_time' | translate }}</div>
							<agilox-analytics-timeselect
								[(data)]="timeEndDay"
								(dataChange)="checkGranularity()"
								[hourMax]="23"
								[(valid)]="endTimeValid"
								(validChange)="checkEndTimeValid()"
							>
							</agilox-analytics-timeselect>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="date-selector__right">
			<div class="date-selector__shift">
				<div class="date-selector__label">{{ 'general.shift.shift_mode' | translate }}</div>
				<ui-button-toggle
					[buttons]="shiftButtons"
					[formControl]="shiftButtonControl"
					(valueChanged)="onShiftChange($event)"
					size="s"
				>
					<span *uiButtonToggleContent="let button">
						{{ button?.label | translate }}
					</span>
				</ui-button-toggle>
			</div>
			<div *ngIf="shiftOn">
				<div class="date-selector__shiftTime">
					<div class="date-selector__shiftTimeBox">
						<div class="date-selector__label">{{ 'general.shift.start_time' | translate }}</div>
						<agilox-analytics-timeselect
							[(data)]="shiftStart"
							(dataChange)="checkGranularity()"
							[hourMax]="23"
							[(valid)]="shiftStartValid"
						>
						</agilox-analytics-timeselect>
					</div>
					<div class="date-selector__shiftTimeBox">
						<div class="date-selector__label">{{ 'general.shift.end_time' | translate }}</div>
						<agilox-analytics-timeselect
							[(data)]="shiftEnd"
							(dataChange)="checkGranularity()"
							[hourMax]="23"
							[(valid)]="shiftEndValid"
						>
						</agilox-analytics-timeselect>
					</div>
				</div>
				<div class="date-selector__shiftDays">
					<div class="date-selector__label">{{ 'general.shift.days' | translate }}</div>
					<ui-button-toggle
						[formControl]="selectedShiftDays"
						[buttons]="days"
						[multiple]="true"
						size="s"
					>
						<span *uiButtonToggleContent="let button">
							{{ button?.label | translate }}
						</span>
					</ui-button-toggle>
				</div>
			</div>

			<div class="date-selector__modeContainer">
				<div class="date-selector__label">
					{{ 'general.datepicker.granularity.statistic_display_mode' | translate }}
				</div>
				<ui-button-toggle
					[buttons]="granularityModes"
					[formControl]="granularityFormControl"
					(valueChanged)="onGranularityChange($event)"
					size="s"
				>
					<span
						*uiButtonToggleContent="let button"
						[uiTooltip]="button.disabled ? button.tooltipDisabled : (button.tooltip | translate)"
					>
						{{ button?.label | translate }}
					</span>
				</ui-button-toggle>
				<div class="date-selector__summary">
					<div class="date-selector__label">{{ 'general.datepicker.summary' | translate }}</div>
					<div class="date-selector__summaryRow">
						<div>{{ 'general.datepicker.time_picker.start_date' | translate }}:</div>
						<div>
							{{ startDay | date: 'd.MM.yyyy' : 'UTC'
							}}<span
								*ngIf="
									!shiftOn &&
									!granularityDaySelected &&
									!granularityWeekSelected &&
									!granularityMonthSelected
								"
							>
								-
								{{ timeStartDay | date: 'HH:mm' : 'UTC' }}</span
							>
						</div>
					</div>
					<div class="date-selector__summaryRow">
						<div>{{ 'general.datepicker.time_picker.end_date' | translate }}:</div>
						<div>
							{{ endDay | date: 'dd.MM.yyyy' : 'UTC'
							}}<span
								*ngIf="
									!shiftOn &&
									!granularityDaySelected &&
									!granularityWeekSelected &&
									!granularityMonthSelected
								"
							>
								-
								{{ timeEndDay | date: 'HH:mm' : 'UTC' }}
							</span>
						</div>
					</div>
					<div class="date-selector__summaryRow">
						<div>{{ 'general.datepicker.granularity.statistic_display_mode' | translate }}:</div>
						<div>
							{{ currentGranularity.label | translate }}
						</div>
					</div>
					<div class="date-selector__summaryRow">
						<div>{{ 'general.shift.shift_mode' | translate }}:</div>
						<div>
							{{ shiftOn ? ('general.on' | translate) : ('general.off' | translate) }}
						</div>
					</div>
					<div class="date-selector__summaryRow" *ngIf="shiftOn">
						<div>{{ 'general.shift.days' | translate }}:</div>
						<div>
							<div
								*ngFor="let selectedDay of selectedShiftDays.value; let idx = index"
								class="date-selector__shiftDay"
							>
								{{ 'general.datepicker.weekdays.' + selectedDay | translate
								}}<span *ngIf="idx < selectedShiftDays.value.length - 1">,</span>
							</div>
						</div>
					</div>
					<div class="date-selector__summaryRow" *ngIf="shiftOn">
						<div>{{ 'general.shift.start_time' | translate }}:</div>
						<div>
							{{ shiftStart | date: 'HH:mm' : 'UTC' }}
						</div>
					</div>
					<div class="date-selector__summaryRow" *ngIf="shiftOn">
						<div>{{ 'general.shift.end_time' | translate }}:</div>
						<div>
							{{ shiftEnd | date: 'HH:mm' : 'UTC' }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="text-right">
		<div class="date-selector__errors">
			<p
				class="date-selector__error"
				*ngIf="!startDay"
				[innerHTML]="'dateselector.error.startday_missing' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="!endDay"
				[innerHTML]="'dateselector.error.endday_missing' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="!shiftStartValid && shiftOn"
				[innerHTML]="'dateselector.error.shiftstart_invalid' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="!shiftEndValid && shiftOn"
				[innerHTML]="'dateselector.error.shiftend_invalid' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="!startTimeValid && !shiftOn"
				[innerHTML]="'dateselector.error.starttime_invalid' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="!endTimeValid && !shiftOn"
				[innerHTML]="'dateselector.error.endtime_invalid' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="isEndTimeBeforeStartTime && !shiftOn"
				[innerHTML]="'dateselector.error.endtime_before_starttime' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="shiftOn && !selectedShiftDays?.value?.length"
				[innerHTML]="'dateselector.error.no_shift_days' | translate"
			></p>
			<p
				class="date-selector__error"
				*ngIf="startDay && endDay && isGranularityToBigForShift()"
				[innerHTML]="'dateselector.error.shift_maximum' | translate"
			></p>
			<ng-container *agiloxAnalyticsRange="let idx of 5">
				<p
					class="date-selector__error"
					*ngIf="
						startDay &&
						endDay &&
						isGranularityToBigForSelectedTime(idx) &&
						!isGranularityToBigForShift()
					"
					[innerHTML]="
						'dateselector.error.maximum'
							| translate
								: {
										granularity: granularityModes[idx].label | translate,
										value: 'dateselector.error.maximumValues.' + idx | translate,
								  }
					"
				></p>
				<p
					class="date-selector__error"
					*ngIf="
						startDay &&
						endDay &&
						isGranularityToSmallForSelectedTime(idx) &&
						!isGranularityToBigForShift()
					"
					[innerHTML]="
						'dateselector.error.minimum'
							| translate
								: {
										granularity: granularityModes[idx].label | translate,
										value: 'dateselector.error.minimumValues.' + idx | translate,
								  }
					"
				></p>
			</ng-container>
		</div>
		<div class="date-selector__buttons">
			<ui-button
				[text]="'general.cancel' | translate"
				(buttonClicked)="invokeCancel()"
				type="link"
			></ui-button>
			<ui-button
				[text]="'general.save' | translate"
				(buttonClicked)="
					((this.shiftOn &&
						this.shiftStartValid &&
						this.shiftEndValid &&
						selectedShiftDays?.value.length) ||
						(!this.shiftOn &&
							this.startTimeValid &&
							this.endTimeValid &&
							!isEndTimeBeforeStartTime)) &&
					this.startDay &&
					this.endDay &&
					checkValidityOfGranularities() &&
					!isGranularityToBigForShift()
						? invokeSave()
						: false
				"
				[disabled]="
					isGranularityToBigForShift() ||
					!startDay ||
					!endDay ||
					!checkValidityOfGranularities() ||
					(shiftOn && (!shiftStartValid || !shiftEndValid || !selectedShiftDays?.value.length)) ||
					(!shiftOn && (!startTimeValid || !endTimeValid || isEndTimeBeforeStartTime))
				"
				data-cy="date-selector-save"
			></ui-button>
		</div>
	</div>
</div>
