import { inject, Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { getSubscriptionRemaining, PluralTranslatePipe, Role, Vehicle } from '@agilox/common';
import { VehicleSelectOptionGroup } from '../models/vehicle-select-option-group.interface';

@Pipe({
	name: 'vehicleSelectOption',
})
export class VehicleSelectOptionPipe implements PipeTransform {
	pluralPipe: PluralTranslatePipe = inject(PluralTranslatePipe);
	transform(
		vehicles: Vehicle[] | null,
		currentlySelectedUnion: string | undefined,
		role: string,
		selectedVehicles?: Vehicle[]
	): VehicleSelectOptionGroup[] {
		if (!vehicles) {
			return [];
		}

		const options = vehicles.map((vehicle) => {
			const subscriptionRemaining: number = getSubscriptionRemaining(vehicle);
			return {
				value: vehicle,
				title: `${vehicle.name} (${vehicle.serial}) | ${vehicle.union}`,
				pillText: this.pluralPipe.transform(
					subscriptionRemaining,
					'subscription.remaining_day',
					'subscription.remaining_days',
					{
						remaining: subscriptionRemaining,
					}
				),
				isDisabled:
					(currentlySelectedUnion && vehicle.unionUuid !== currentlySelectedUnion) ||
					(subscriptionRemaining <= 0 && role === Role.partner),
				pillDisabled: subscriptionRemaining <= 0,
			};
		});
		const grouped: VehicleSelectOptionGroup[] = this.groupByUnion(options);
		return this.sortGroup(grouped, currentlySelectedUnion || '', selectedVehicles);
	}

	/**
	 * The selected group should be the first one in the list
	 * @param groups
	 * @param union
	 * @param selectedVehicles
	 * @private
	 */
	private sortGroup(
		groups: VehicleSelectOptionGroup[],
		union: string,
		selectedVehicles?: Vehicle[]
	): VehicleSelectOptionGroup[] {
		const selectedGroup = groups.find((group) => group.uuid === union);
		if (selectedGroup) {
			/**
			 * The selected options in the group should be the first ones
			 */
			selectedGroup.options = selectedGroup.options.sort((a, b) => {
				return selectedVehicles?.find((vehicle: Vehicle) => vehicle?.serial === a.value?.serial)
					? -1
					: 1;
			});

			/**
			 * The selected group should be the first one
			 */
			groups.splice(groups.indexOf(selectedGroup), 1);
			groups.unshift(selectedGroup);
		}
		return groups;
	}

	private groupByUnion(vehicles: SelectOption<Vehicle>[]): VehicleSelectOptionGroup[] {
		const groupedVehicles = new Map<string, SelectOption<Vehicle>[]>();
		vehicles.forEach((vehicle) => {
			if (groupedVehicles.has(vehicle.value?.unionUuid || '')) {
				groupedVehicles.get(vehicle.value?.unionUuid || '')?.push(vehicle);
			} else {
				groupedVehicles.set(vehicle.value?.unionUuid || '', [vehicle]);
			}
		});
		return Array.from(groupedVehicles.entries()).map(([name, options]) => ({
			name: options[0].value?.union || '',
			uuid: name,
			options,
		}));
	}
}
