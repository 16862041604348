/**
 * Represents a station
 */
export class Station {
	/**
	 * generates a station
	 * @param name
	 * @param x
	 * @param y
	 * @param length
	 * @param width
	 * @param angle
	 * @param floor
	 * @param type
	 * @param dollyCount
	 */
	constructor(
		public name: string,
		public x: number,
		public y: number,
		public length: number,
		public width: number,
		public angle: number,
		public floor: number,
		public type: string,
		public dollyCount: number
	) {}

	/**
	 * generates a station from a received json object
	 * @param data data of the stations request
	 */
	static generateFromJson(data: any): Station {
		return new Station(
			data.name,
			data.x,
			data.y,
			data.length,
			data.width,
			data.angle / 100,
			data.floor,
			data.type,
			data.dollycount
		);
	}
}
