/**
 * Abstract-info about the days and their position in the calendar
 */
export enum DayType {
	first = 'first',
	last = 'last',
	between = 'between',
	inSideMonth = 'inSideMonth',
	outSideMonth = 'outSideMonth',
	firstAndLast = 'firstAndLast',
}
