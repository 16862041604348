<div
	uiDropdown
	[fullWidth]="true"
	[ignoreTrigger]="true"
	(openChange)="onDropdownStateChange($event)"
>
	<div
		class="ui-datepicker-input"
		[ngClass]="{
			'ui-datepicker-input--disabled': formControl.disabled,
			'ui-datepicker-input--error': hasError,
			'ui-datepicker-input--valid': !hasError && showWhenValid,
		}"
		uiDropdownTrigger
		[uiDropdownDisabled]="formControl.disabled || hasError"
	>
		<div class="ui-datepicker-input__inputWrapper">
			<div
				class="ui-datepicker-input__input"
				[ngClass]="{ 'is-focused': focused || dropdownOpened }"
			>
				<input
					class="ui-datepicker-input__inputField"
					[id]="inputId"
					[placeholder]="placeholder"
					[formControl]="formControl"
					(focusout)="focused = false"
					(focusin)="focused = true"
					#input
				/>
				<div class="ui-datepicker-input__icons">
					<ui-icon
						icon="cal"
						size="s"
						class="ui-datepicker-input__icon"
						[disabled]="formControl.disabled"
						(iconClicked)="openCalendar()"
					></ui-icon>
				</div>
			</div>
		</div>
	</div>
	<ng-container *uiDropdownContent>
		<div class="ui-datepicker-input__calendar">
			<ui-datepicker-base
				[currentlyDisplayedMonth]="currentlyDisplayedMonth"
				[disabled]="formControl.disabled"
				[selectedDate]="formControl.value | dateStringToISO"
				(dateSelectionChange)="calendarSelection($event)"
				(navDateChange)="navDateChange($event)"
				[minDate]="minDate"
				[maxDate]="maxDate"
				[useMinDateAsStartDate]="useMinDateAsStartDate"
			></ui-datepicker-base>
		</div>
	</ng-container>
</div>
