<ui-select
	[options]="vehicleResponse | async | vehicleSelect: selectedVehicles"
	[formControl]="formControl"
	fieldToCompare="serial"
	[searchEnabled]="true"
	(search)="onSearch($event)"
	(scrollEnd)="onScroll()"
	[multiple]="true"
	inputId="ui-vehicles-select"
	(opened)="onOpened()"
>
	<label label for="ui-vehicles-select">{{ 'general.vehicles' | translate }}</label>
</ui-select>
