// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	environmentName: 'testing',
	server: '/api',
	excludedRoutes: [
		'/v2/User/login',
		'/v2/User/logout',
		'/v2/User/setPwd',
		'/v2/User/saveSettings',
		'/v2/Admin/addMachinePermission',
		'/v2/Admin/deletePermission',
		'/v2/Admin/updateUser',
		'/v2/Admin/addUser',
		'/v2/Support/requestRemote',
		'/v2/Admin/updateMachine',
		'/v2/machineMgmt/machineBackup',
		'/v2/Stats/downloadMaps',
		'/v2/Support/assignPartner',
		'/v2/Support/assignUsersToCompany',
		'/v2/AzureDevice/downloadAzureData',
	],
	excludedRoutePatterns: ['assets/img/', 'assets/i18n/', 'connect'],
	cookieDomain: '',
};
