import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderMenu } from 'apps/analytics/src/app/general/shared-desktop/selector/header-menu.enum';
import { SelectorService } from 'apps/analytics/src/app/general/shared-desktop/selector/selector.service';

@Component({
	selector: 'agilox-analytics-not-for-appliance',
	templateUrl: './not-for-appliance.component.html',
	styleUrls: ['./not-for-appliance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotForApplianceComponent {
	constructor(private selectorService: SelectorService) {}

	openMachineSelector(event: MouseEvent) {
		event.stopPropagation();
		event.stopImmediatePropagation();
		event.preventDefault();
		this.selectorService.openSelector({ menu: HeaderMenu.machines, event });
	}
}
