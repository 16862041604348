import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { DayHolder } from './day-holder.interface';
import { DayType } from './day-type.enum';

@Component({
	selector: 'agilox-analytics-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent {
	/** holds day types */
	dayType = DayType;

	/** current month */
	month: number;

	/** current year */
	year: number;

	/** holds all years to select */
	years = new Array<number>();

	yearOptions: Array<SelectOption<number>> | undefined;

	/** css class modifiers for the day */
	dayStyles = [
		'--startDay',
		'--endDay',
		'--inBetween',
		'--inMonth',
		'--grayedOut',
		'--firstAndLast',
		'--disabled',
	];

	/** css class modifiers for the day text*/
	dayTextStyles = ['--inBetween', '--grayedOut', '--firstAndLast'];

	/** minimum date 1.1.2017 */
	minimumDate = 1483228800000;

	/**
	 * ms of the month to show
	 */
	@Input() set show(val: number) {
		if (val) {
			const date = new Date(val);
			this.month = date.getUTCMonth();
			this.year = date.getUTCFullYear();
		}
	}

	/** holds the start day  */
	_startDay: DayHolder;

	/** get start day (always returns a dayholder) */
	get startDay(): number | DayHolder {
		return this._startDay;
	}

	/**
	 * sets the start day
	 * sets month and year if it is the calendar of start day
	 * must always receive a number
	 */
	@Input() set startDay(value: number | DayHolder) {
		if (this.isDayHolder(value)) {
			throw Error('please pass a number for startDay');
		}
		const date = new Date(value as number);
		this._startDay = { day: date.getUTCDate(), unixMs: +date, dayType: DayType.first };
		this.setDaysToDisplay();
	}

	/**
	 * gets invoked if start date has changed
	 */
	@Output() startDayChanged: EventEmitter<number>;

	/** holds the end day */
	_endDay: DayHolder;

	/** get end day is always a dayholder*/
	get endDay(): number | DayHolder {
		return this._endDay;
	}

	/**
	 * sets the end day
	 * sets month and year if it is the calendar of end day
	 * must always receive a number
	 */
	@Input() set endDay(value: number | DayHolder) {
		if (value) {
			if (this.isDayHolder(value)) {
				throw Error('please pass a number for endDay');
			}
			const date = new Date(value as number);
			this._endDay = { day: date.getDate(), unixMs: +date, dayType: DayType.last };
		} else {
			this._endDay = null;
		}
		this.setDaysToDisplay();
	}

	/**
	 * gets invoked if end date has changed
	 */
	@Output() endDayChanged: EventEmitter<number>;

	/** holds all days to display in the calendar */
	daysToDisplay: Array<DayHolder>;

	/** current day */
	currentDay: Date;

	constructor(private changeDetection: ChangeDetectorRef) {
		this.startDayChanged = new EventEmitter<number>();
		this.endDayChanged = new EventEmitter<number>();

		this.setDefaultDate();
	}

	/**
	 * sets the default date
	 */
	setDefaultDate() {
		this.currentDay = new Date(Date.now());
		this.month = this.currentDay.getUTCMonth();
		this.year = this.currentDay.getUTCFullYear();
		this.yearOptions = [];

		const max = new Date().getUTCFullYear();
		const min = 2017;

		for (let i = min; i <= max; i++) {
			this.years.push(i);
			this.yearOptions.push({
				title: i.toString(),
				value: i,
			});
		}
	}

	/**
	 * returns calendar day style
	 * @param day day
	 */
	calendarStyle(day: DayHolder): string {
		let result = '';

		if (day.unixMs > Date.now() || day.unixMs < this.minimumDate) {
			result = 'calendar__calendarDay--disabled calendar__calendarDay--grayedOut';
		} else {
			Object.keys(DayType).forEach((dayTypeKey: string, index: number) => {
				if (day.dayType.includes(dayTypeKey)) {
					if (result.length === 0) {
						result = 'calendar__calendarDay' + this.dayStyles[index];
					} else {
						result += ' calendar__calendarDay' + this.dayStyles[index];
					}
				}
			});
		}
		return result;
	}

	/**
	 * returns calendar text styles
	 * @param day day
	 */
	calendarTextStyles(day: DayHolder): string {
		let result = '';
		if (day.unixMs > Date.now()) {
			result = 'calendar__calendarDayText--grayedOut';
		} else {
			[DayType.between, DayType.outSideMonth, DayType.firstAndLast].forEach(
				(dayTypeKey: string, index: number) => {
					if (day.dayType.includes(dayTypeKey)) {
						result = 'calendar__calendarDayText' + this.dayTextStyles[index];
					}
				}
			);
		}
		return result;
	}

	/**
	 * gets invoked when year selected
	 */
	yearSelected() {
		if (this.month > this.currentDay.getUTCMonth()) {
			this.month = this.currentDay.getUTCMonth();
		}

		this.setDaysToDisplay();
	}

	/**
	 * gets invoked when left/arrow is clicked
	 * moves forward or backward one month
	 * @param add
	 * true -> right arrow
	 * false -> left arrow
	 */
	modifyMonth(add: boolean) {
		if (add) {
			if (this.month < 11) {
				this.month++;
			} else {
				this.month = 0;
				this.year++;
			}
		} else {
			if (this.month > 0) {
				this.month--;
			} else {
				this.month = 11;
				this.year--;
			}
		}
		this.setDaysToDisplay();
	}

	/**
	 * returns the type of the day
	 * @param year
	 * @param month
	 * @param date
	 */
	getDayType(date: Date): DayType {
		let type = DayType.outSideMonth;
		const lastDayOfMonth = Date.UTC(this.year, this.month + 1, 0);
		const firstDayOfMonth = Date.UTC(this.year, this.month, 1);

		// checks if the day is in the current month
		if (+date >= firstDayOfMonth && +date <= lastDayOfMonth) {
			// checks if only one day is selected
			if (
				this.onSameDay(+date, (this.endDay as DayHolder)?.unixMs) &&
				this.onSameDay(+date, (this.startDay as DayHolder)?.unixMs)
			) {
				type = DayType.firstAndLast;
			}
			// checks if the day is the start day
			else if (this.onSameDay(+date, (this.startDay as DayHolder).unixMs)) {
				type = DayType.first;
			}
			// checks if the day is the end day
			else if (this.onSameDay(+date, (this.endDay as DayHolder)?.unixMs)) {
				type = DayType.last;
			}
			// checks if the day is between start and end day
			else if (+date >= this._startDay.unixMs && +date <= this._endDay?.unixMs) {
				type = DayType.between;
			} else {
				type = DayType.inSideMonth;
			}
		}

		return type;
	}

	/**
	 * selects the day and emits start and end day
	 * @param day selected day
	 */
	selectDay(day: DayHolder) {
		if (this.startDay === null || this.endDay !== null) {
			this._startDay = day;
			this.startDayChanged.emit((this.startDay as DayHolder).unixMs);
			this._endDay = null;
			this.endDayChanged.emit(null);
		} else {
			if ((this.startDay as DayHolder).unixMs > day.unixMs) {
				this._endDay = this._startDay as DayHolder;
				this._startDay = day;
				this.startDayChanged.emit((this.startDay as DayHolder).unixMs);
				this.endDayChanged.emit((this.endDay as DayHolder).unixMs);
			} else {
				this._endDay = day;
				this.endDayChanged.emit((this.endDay as DayHolder).unixMs);
			}
		}
		this.resetDayType();
		this.changeDetection.detectChanges();
	}

	resetDayType() {
		this.daysToDisplay.map((day) => {
			day.dayType = this.getDayType(new Date(day.unixMs));
		});
	}

	/**
	 * sets the days to display in the calendar
	 */
	setDaysToDisplay() {
		this.daysToDisplay = new Array<DayHolder>();
		const firstDayOfMonth = new Date(Date.UTC(this.year, this.month));

		let precedingDays = firstDayOfMonth.getUTCDay() - 1;
		if (precedingDays === -1) {
			precedingDays = 6;
		}

		// set all days before the first of the current month
		for (; precedingDays > 0; precedingDays--) {
			const wantedDate = new Date(Date.UTC(this.year, this.month, 1));
			wantedDate.setUTCDate(wantedDate.getUTCDate() - precedingDays);
			this.daysToDisplay.push({
				day: wantedDate.getUTCDate(),
				unixMs: +wantedDate,
				dayType: DayType.outSideMonth,
			});
		}

		// set the first day of the current month
		this.daysToDisplay.push({
			day: firstDayOfMonth.getUTCDate(),
			unixMs: +firstDayOfMonth,
			dayType: this.getDayType(firstDayOfMonth),
		});

		// set the rest of the days
		for (let i = 0; this.daysToDisplay.length !== 42; i++) {
			firstDayOfMonth.setUTCDate(firstDayOfMonth.getUTCDate() + 1);
			this.daysToDisplay.push({
				day: firstDayOfMonth.getUTCDate(),
				unixMs: +firstDayOfMonth,
				dayType: this.getDayType(firstDayOfMonth),
			});
		}
	}

	/**
	 * checks if day is clickable
	 * is needed for December of the current year so that the grayed out days of January of the next year cannot be clicked
	 * and for January 2017 so that the grayed out days of December of 2016 cannot be clicked
	 * @param day day want to be clicked
	 */
	dayClickable(day: DayHolder): boolean {
		if (day.dayType === DayType.outSideMonth) {
			const newMonth = new Date(day.unixMs).getUTCMonth();
			const firstDaysOfJanuary =
				this.year === this.years[this.years.length - 1] && this.month === 11 && newMonth === 0;
			const lastDaysOfDecember = this.year === this.years[0] && this.month === 0 && newMonth === 11;
			return firstDaysOfJanuary || lastDaysOfDecember;
		} else {
			return false;
		}
	}

	/**
	 * checks if the dayholders are on the same day
	 * @param a
	 * @param b
	 */
	private onSameDay(aMs: number, bMs: number): boolean {
		const dayA = new Date(aMs);
		const dayB = new Date(bMs);
		return (
			dayA.getUTCFullYear() === dayB.getUTCFullYear() &&
			dayA.getUTCMonth() === dayB.getUTCMonth() &&
			dayA.getUTCDate() === dayB.getUTCDate()
		);
	}

	/**
	 * checks if value is a DayHolder
	 * @param value
	 */
	isDayHolder(value: number | DayHolder): value is DayHolder {
		return (value as DayHolder).day !== undefined;
	}
}
